import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Images } from "../../constants/images";

import SuperHeroDayMapSection from "../registration/allDayMap";
import OurCause from "./ourCause";
import OurBeneficiaries from "../registration/ourBeneficiaries";
import SupportSection from "../../components/supportSection";
import SuperHero from "../../components/superHeroSection";
import OurEventSupporter from "../../components/ourEventSupporter";
import Ambassadors from "./ambassadors";

function ShishCyclothon2024() {
  const [isMobile, setIsMobile] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Shish Cyclothon: Pedal for Healthier Living and a Greener Planet
        </title>
        <meta
          name="description"
          content="Join Shish Cyclothon by Shish Foundation! Pedal for a healthier lifestyle, embrace cycling culture, and contribute to a better planet."
        />
      </Helmet>
      <div className="relative">
        <img src={Images.cycleGreenEnergy} alt="greenEnergy" />
        <div className="absolute top-[6.281vw] sm:top-[8.281vw] md:top-[8.281vw] left-0 right-0 mx-auto">
          <span className="text-[#40403F] text-[20px] md:text-[40px] xl:text-[60px] font-bold flex justify-end text-right mr-[20px] sm:mr-[100px] xl:mr-[200px]">
            SUSTAINABILITY & <br /> HEALTH BY <br /> INDIAN <br /> SUPERHEROES
          </span>
        </div>
      </div>

      <div className="bg-[#F1F6FF]">
        <div
          className="relative xl:mt-[-140px] w-full xl:w-[80%] m-auto bg-[#1A1917] py-[10px] md:py-[64px] px-[10px] md:px-[50px] z-10"
          style={{ boxShadow: "0px 4px 10.1px -1px rgba(0, 0, 0, 0.25)" }}
        >
          <span className="text-[12px] md:text-[24px] text-white flex justify-center text-center">
            Shish Cyclothon is organized by the Shish Foundation, with the aim
            of cultivating a dynamic sport and cycling culture not only within
            the city but also extending across the entire nation. Our earnest
            endeavor is to instigate a transformative shift in people's
            lifestyles and contribute meaningfully to the betterment of our
            planet.
          </span>
        </div>
        <div className="mt-[20px] md:mt-[50px] flex flex-col pb-[20px]">
          <span className="gradient_txt text-[22px] sm:text-[40px] xl:text-[54px] uppercase text-center font-bold">
            pratigya of
          </span>
          <span className="gradient_txt text-[22px] sm:text-[40px] xl:text-[60px] uppercase text-center font-bold">
            superheroes
          </span>
          <span className="md:text-[22px] flex justify-center text-center m-auto w-full md:w-[70%] px-[20px] md:px-0 mt-[10px] md:mt-[50px]">
            Our 333 Superheroes are the vital part of Shish Group, and they are
            ready to embark on a 3-day journey as participants in the Shish
            Cyclothon.
          </span>
          <img
            src={isMobile <= 425 ? Images.mobileQuote1 : Images.quote1}
            className="w-full md:w-[80%] px-[20px] md:px-0 m-auto"
            alt="Living Sustainably and Harmony with Nature"
          />
        </div>
      </div>

      <SuperHeroDayMapSection />

      <OurCause />
      <OurBeneficiaries />
      <Ambassadors />
      <SuperHero />
      <OurEventSupporter />
      <SupportSection />
      <OurEventSupporter isSponsers />
    </div>
  );
}

export default ShishCyclothon2024;
