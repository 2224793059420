import { useEffect, useState } from "react";
import { Images } from "../constants/images";
import { IconButton } from "@material-tailwind/react";
import { Drawer, Menu as MainMenu } from "antd";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMobile, setIsMobile] = useState(window.screen.width);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerClose = () => {
    setOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }

  const menuList = [
    {
      title: "Home",
      link: "/",
      id: 1,
    },
    {
      title: "Registration",
      link: "/register",
      id: 2,
    },
    {
      title: "Shish Cyclothon 2024",
      link: "/shishCyclothon",
      id: 3,
    },
    {
      title: "About Us",
      link: "/aboutus",
      id: 4,
    },
    {
      title: "Media",
      link: "/media",
      id: 5,
    },
  ];

  const items = (pages) => {
    const sideMenuList = pages.map((data, index) => {
      if (data.submenu && data.submenu.length) {
        const getInnerSubItem = (_data) => {
          let innerItem = [];
          return _data.map((item, _innerIndex) => {
            innerItem = getItem(item.title, item.link, "");
            return innerItem;
          });
        };
        return getItem(data.title, data.link, getInnerSubItem(data.submenu));
      } else {
        return getItem(data.title, data.link, "");
      }
    });
    return sideMenuList;
  };

  return (
    <div className="px-5 md:px-10 py-[10px] bg-white flex justify-between">
      <img
        alt="Shish Cyclothon Logo"
        src={Images.logo}
        className="w-[250px] sm:w-[320px] cursor-pointer"
        onClick={() => navigate("/")}
      />

      {isMobile < 1024 ? (
        <div className="flex items-center">
          <div onClick={showDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              stroke-width="3"
              class="h-6 w-6 mb-3 text-gray-900"
            >
              <path
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10zm0 5.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <Drawer placement="left" onClose={onClose} open={open}>
            <MainMenu
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              theme="light"
              items={items(menuList)}
              onClick={(event) => {
                showDrawerClose(false);
                navigate(event.key);
                // openMenu(event.key);
              }}
            />
          </Drawer>
        </div>
      ) : (
        <>
          <ul className="font-medium flex items-center gap-x-8">
            {menuList
              .filter((menu) => menu.id)
              .map((menu) => (
                <li key={menu.title}>
                  <Link
                    className={
                      "font-semibold text-[1.042vw] text-[#091635] rounded  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 hover:scale-110 transition-all"
                    }
                    style={
                      pathname === menu.link
                        ? {
                            background:
                              "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
                            WebkitBackgroundClip: "text",
                            backgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }
                        : {}
                    }
                    to={menu.link}
                  >
                    {menu.title}
                  </Link>
                </li>
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Navbar;
