import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async";

import { Images } from '../../constants/images'
import RegisterForm from './registerForm';

function Registration() {
  const [isMobile, setIsMobile] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Secure Your Spot: Register for Shish Cyclothon 2024 Today!
        </title>
        <meta
          name="description"
          content="Are you ready to pedal for a purpose? Register now and become a part of the Shish Cyclothon 2024, a thrilling journey towards a healthier and greener future!"
        />
      </Helmet>
      <div className="relative">
        <img
          alt="3 cyclists on a road at sunset"
          src={Images.registerHeroSectionBanner}
          className="w-full object-fill"
        />
        <div className="absolute top-[13.281vw] left-0 right-0 mx-auto ml-0 md:ml-[150px]">
          <h1 className="text-[20px] md:text-[40px] lg:text-[70px] text-white text-center md:text-left font-bold">
            JOIN THE {isMobile > 768 ? <br /> : ""} SHISH CYCLOTHON {isMobile > 768 ? <br /> : ""} 2024
          </h1>
          <h2 className='text-white text-[12px] md:text-[20px] lg:text-[35px] text-center md:text-left font-semibold'>
            <span>Pedal Towards Sustainability {isMobile > 768 ? <br /> : ""} and Health!</span>
          </h2>
        </div>
      </div>

      <div className='bg-[#1A1917] text-center py-[16px] md:py-[56px] px-[20px] md:px-[100px] xl:px-[200px]'>
        <span className='text-white text-[12px] md:text-[20px] lg:text-[30px]'>Are you ready to pedal for a purpose? Register now and become a part of the Shish Cyclothon 2024, a thrilling journey towards a healthier and greener future!</span>
      </div>

      <RegisterForm />
    </>
  )
}

export default Registration