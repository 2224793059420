import React, { useEffect, useState } from "react";
import { Images } from "../../../constants/images";

const OurBeneficiaries = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="pt-5 pb-10 bg-[#F1F6FF] flex flex-col items-center">
      <h6 className="py-4 uppercase text-[34px] text-center text-[#B5C8EB]">
        Our Beneficiaries
      </h6>
      <div
        className={`flex flex-row  ${
          screenSize <= 355 ? "gap-2" : "gap-20"
        }  mx-3`}
      >
        <img
          className={`w-[130px] lg:w-[370px] sm:w-[330px] ${
            screenSize <= 355 ? "w-[100px]" : ""
          }`}
          alt="Manav Seva Cheritable Trust Logo "
          src={Images.beneficiaries1}
        />
        <img
          className={`w-[130px] lg:w-[370px] sm:w-[330px] ${
            screenSize <= 355 ? "w-[100px]" : ""
          }`}
          alt="Sevatirth Logo"
          src={Images.Savartirath}
        />
      </div>
    </div>
  );
};

export default OurBeneficiaries;
