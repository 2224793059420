import { Modal } from "antd";
import React, { useRef } from "react";
import YouTube from "react-youtube";

const VideoModal = ({ confirm, setConfirm, closable, url, title }) => {
  const playerRef = useRef(null);
  const videoId = url.split("/").pop();
  const onReady = (event) => {
    playerRef.current = event.target;
  };
  return (
    <div>
      <Modal
        centered
        closable={closable}
        width={680}
        open={confirm}
        onCancel={() => {
          setConfirm(false);
          playerRef?.current?.pauseVideo();
        }}
        footer={null}
        title={title}
      >
        {/* <>
          <div className="sm:w-[690px] sm:h-[330px] md:w-[855px] md:h-[430px]">
            <iframe
              width="100%"
              height="100%"
              src={url}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              //   allowfullscreen
            ></iframe>
          </div>
        </> */}
        <YouTube
          iframeClassName="w-full h-[300px]"
          videoId={videoId}
          onReady={onReady}
        />
      </Modal>
    </div>
  );
};

export default VideoModal;
