import React from "react";
import { Images } from "../../constants/images";

function AboutShish() {
  return (
    <div className="px-[20px] md:px-[70px] py-[26px] flex flex-col md:flex-row bg-[#F1F6FF]">
      <div className="w-full md:w-[80%] py-[20px] md:py-[80px]">
        <div className="h-auto">
          <div className="flex items-center mb-[10px]">
            <img src={Images.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-[#1A1917] text-[30px] sm:text-[40px] font-normal">
              ABOUT{" "}
              <span className="text-[30px] sm:text-[48px] font-extrabold">
                SHISH
              </span>
            </span>
          </div>
        </div>
        <div className="flex flex-row sm:hidden items-center justify-center">
          <img
            src={Images.shishLogo2}
            alt="fit india"
            className="w-auto sm:w-[440px] h-[175px] sm:h-[340px] "
          />
        </div>
        <div className="sm:mt-[70px]">
          <span className="text-[22px] text-black">
            We are a group of remarkable companies working together across
            diverse industries to forge a brighter future. Our family of
            companies all share a common set of core values, reliability,
            innovation, sustainability and a strong customer-centric approach in
            our business, which elevates our businesses to new heights and
            serves the best interests of our community.
          </span>
          <button
            className="w-[200px] mt-5 self-center py-1.5 text-[22px] text-center text-white rounded-[10px] cursor-pointer"
            style={{
              background: "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
            }}
            onClick={() => {
              window.open("https://www.shishgroup.com/", "_blank");
            }}
          >
            Know More
          </button>
        </div>
      </div>

      <div className="hidden sm:block w-full md:w-[25%]  justify-center ">
        <img
          src={Images.shishLogo2}
          alt="fit india"
          className="w-auto sm:w-[440px] h-auto sm:h-[340px]"
        />
      </div>
    </div>
  );
}

export default AboutShish;
