import { Helmet } from "react-helmet-async";

import { Images } from "../../constants/images";
import CommonBanner from "../../components/commonBanner";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>
          Shish Cyclothon: Promoting Sustainability and Community Health
        </title>
        <meta
          name="description"
          content="The Shish Cyclothon, an annual event organized by the Shish Foundation, serves as a platform to promote awareness regarding sustainability and health within communities."
        />
      </Helmet>
      <CommonBanner img={Images.aboutUsBg} />
      <div className="lg:grid lg:grid-cols-2">
        <div className="py-[20px] md:py-10 lg:py-0 px-[16px] md:px-10 lg:px-20 flex flex-col justify-center">
          <h6 className="mb-7 md:text-[35px] font-semibold">
            The Shish Cyclothon, an annual event organized by the Shish
            Foundation, serves as a platform to promote awareness regarding
            sustainability and health within communities.
          </h6>
          <p className="md:last:text-[24px] md:pr-10">
            Our mission is to inspire a holistic lifestyle, emphasizing the
            symbiotic relationship between personal well-being and environmental
            health. Join us in fostering a harmonious coexistence with the
            Earth, prioritizing individual health and vitality.
          </p>
        </div>
        <img
          alt="Uniting Health and Sustainability"
          src={Images.aboutus1}
          className="w-full h-full object-fill"
        />
      </div>
      <div className="flex flex-col-reverse lg:grid lg:grid-cols-2">
        <img
          alt="Synergy For Tomorrow"
          src={Images.aboutus2}
          className="w-full object-fill"
        />
        <div className="py-[20px] md:py-10 lg:py-0 px-[16px] md:px-10 lg:px-20 flex flex-col justify-center">
          <h6 className="mb-7 md:text-[35px] font-semibold">
            The Shish Cyclothon event is a reflection of Shish Group's
            dedication to corporate social responsibility (CSR).
          </h6>
          <p className="md:text-[24px] md:pr-10">
            We are a group of exceptional companies collaborating across varied
            industries, united in our pursuit of a more promising future. Our
            companies share a cohesive set of fundamental values that drive our
            businesses to new levels of success, all while benefiting the
            well-being of our community.
          </p>
          <button
            className="w-[200px] mt-5 self-center py-1.5 text-[22px] text-center text-white rounded-[10px] cursor-pointer"
            style={{
              background: "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
            }}
            onClick={() => {
              window.open("https://www.shishindustries.com/csr", "_blank");
            }}
          >
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
