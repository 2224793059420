import React, { useEffect } from "react";
function PrivacyPolicy() {
  useEffect(() => {
    window.scroll({
      top: 0,
    });
  }, []);

  let policy = `<html>
    <head>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <title></title>
      <meta name="generator" content="LibreOffice 7.4.3.2 (Linux)" />
      <meta name="author" content="Kinjal Joshi" />
      <meta name="created" content="2023-12-16T09:24:12.571231700" />
      <meta name="changedby" content="Kinjal Joshi" />
      <meta name="changed" content="2023-12-27T12:58:18.355468200" />
      <meta name="AppVersion" content="16.0000" />
      <style type="text/css">
        @page {
          size: 8.5in 11in;
          margin: 1in;
        }
        p {
          line-height: 115%;
          text-align: left;
          orphans: 2;
          widows: 2;
          margin-bottom: 0.1in;
          direction: ltr;
          background: transparent;
        }
      </style>
    </head>
    <body lang="en-US" link="#000080" vlink="#800000" dir="ltr">
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="4" style="font-size: 14pt"
            ><span lang="en-US"><b>Privacy Policy</b></span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >Please keep in mind that when you provide information to us on a
              third-party site or platform (for example, via our website or
              applications like social media login (for e.g. Facebook, Google+
              etc.), the information we collect is through those third-party sites
              linked with our websites and applications and is covered by this
              Privacy Policy, and the information the third-party site or platform
              collects is subject to the third-party site or platform’s privacy
              practices. Privacy choices you have made on the thirdparty site or
              platform will not apply to our use of the information we have
              collected directly through our websites or applications. Please also
              keep in observance that our websites and applications may contain
              links to other sites not owned or controlled by us and we are not
              responsible for the privacy practices of those sites. We encourage
              you to be aware that when you leave our websites or applications to
              read the privacy policies of other sites, they may collect your
              personal information.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >By using Shish Cyclothon’s website and its services (for example,
              when you visit our website or browse through various other portions
              of our websites or register via social media or via the registration
              form), you agree to provide consent to our collection, use and
              sharing of your personal information as described in this Privacy
              Policy. In some cases, particularly if you reside in a country
              governed by a data protection regulation, we may ask you to provide
              explicit consent to access our services before proceeding for
              further operations.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <a name="_Int_2UNwNk1Z"></a><a name="_Int_U7z0HN6p"></a
        ><a name="_Int_Y2WP5XyI"></a><a name="_Int_rJ5ds1jO"></a> “<font
          face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >Personal Information” or “PII” is defined as any information that
              identifies (whether directly or indirectly) to a particular
              individual or natural person, such as the individual’s name, postal
              address, email address, mobile number and any other identifier
              indicating to that person. When anonymous information is directly or
              indirectly associated with personal information, the resulting
              information also is treated as personal information. The Company
              respects the privacy of the users of the Services they use and is
              committed to reasonably protect it in all respects. The information
              about the user as collected by the Company are: 1. Information
              supplied by users 2. Information automatically tracked while
              navigation 3. Inferred information through usage and log data 4.
              Information collected from any other sources (like third party
              providers or social media platforms)</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <a name="_Int_xwM0kQ2N"></a>
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b
                >Information automatically collected while browsing/navigating
                through the website.
              </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"><b>Cookies </b></span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >To improve the responsiveness of the “website” for our users, we
              may use “cookies”, or similar electronic tools to collect
              information to assign each visitor a unique, random number as a User
              Identification (User ID) to understand the user’s individual
              interests using the identified computer. Unless you voluntarily
              identify yourself (through registration, for example), we will have
              no way of knowing who you are, even if we assign a cookie to your
              computer. The only personal information a cookie can contain is
              information you supply. A cookie cannot read data off your hard
              drive. Our advertisers may also assign their own cookies to your
              browser (if you click on their ads), a process that we do not
              control. We receive and store certain types of information whenever
              you interact with us via the website.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >1. Inferred Information through usage and log data
            </span></font
          ></font
        >
      </p>
      <ol type="a">
        <li>
          <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
            <font face="Calibri, serif"
              ><font size="2" style="font-size: 11pt"
                ><span lang="en-US"
                  >We may track certain information about you based upon your
                  behaviour on using Shish Cyclothon Services.
                </span></font
              ></font
            >
          </p>
        </li>
  
        <li>
          <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
            <font face="Calibri, serif"
              ><font size="2" style="font-size: 11pt"
                ><span lang="en-US"
                  >We use this information for conducting internal research on our
                  users' demographics, devices, interests, and behaviour to better
                  understand, protect and serve our users. We may also collect
                  Your information to track user behaviour and preferences for
                  internal analytics and research. We may also use your
                  information i. To evaluate your interest in various genres of
                  content, services, offers; ii. To perform analytics and conduct
                  customer research, to determine your interest, for identifying
                  content that generates sales and to analyse traffic patterns.
                </span></font
              ></font
            >
          </p>
        </li>
      </ol>
  
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >If you choose to post messages on social media accounts, message
              boards, or other message areas or leave feedback, we will collect
              that information you provide to us. We retain this information as
              necessary to resolve disputes, provide customer support and
              troubleshoot problems as permitted by law. The above information may
              also be captured by keeping cookies on our websites by our
              Affiliates for the purposes mentioned above If you send us personal
              correspondence, such as emails or letters, or if other users or
              third parties send us correspondence about your activities or
              postings on our services, we may collect and store such
              information.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b>Information obtained from other sources </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >We may receive information about you from other sources, add it to
              our account information and treat it in accordance with this Privacy
              Policy. If you provide information to the platform provider or other
              partner to whom we provide services, your account information and
              order information may be passed on to us. We may obtain updated
              contact information from third parties in order to correct our
              records and fulfil the Services or to communicate with you.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b>Demographic and purchase information </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >We may reference other sources of demographic and other information
              in order to provide you with more targeted communications and
              promotions. We use Google Analytics, among others, to track the user
              behaviour on our website. Google Analytics specifically has been
              able to support display advertising towards helping us gain
              understanding of our users’ demographics and interests. The reports
              are anonymous and cannot be associated with any individual
              personally identifiable information that you may have shared with
              us.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"><b>Links to third party websites </b></span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >The website/application may include links to other websites or
              applications. Such websites or applications are governed by their
              respective privacy policies, which are beyond our control. Once you
              leave our servers, use of any information you provide is governed by
              the privacy policy of the operator of the application or website you
              are visiting. That policy may differ from ours. If you can't find
              the privacy policy of any of these sites via a link from the
              application's/website homepage, you should contact the
              application/website owners directly for more information.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b>Information use by the Company </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >The information supplied by you enables us to improve our services
              and provide you with the most userfriendly experience(s). In some
              cases, for e.g. provision of certain service(s) or utility (ies), we
              may require your contact information as well. All required
              information is service dependent, and the Company may use the above
              said user information to maintain, protect, and improve the Services
              (including advertising) and for developing new services. Any
              personally identifiable information provided by you will not be
              considered as sensitive if it is freely available and/or accessible
              in the public domain like any comments, messages, blogs, scribbles
              available on social platforms like Facebook, Twitter etc.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >Any posted/uploaded/conveyed/communication by users on the public
              sections of the &quot;application/websites&quot; becomes published
              content and is not considered personally identifiable information
              subject to this Privacy Policy. In case you choose to decline to
              submit personally identifiable information on the
              application/websites, we may not be able to provide certain services
              on the application/websites to you. We will make reasonable efforts
              to notify you of the same at the time of opening your account. In
              any case, we will not be liable and/or responsible for the denial of
              certain services to you for lack of you providing the necessary
              personal information. When you register with our Services, we may
              contact you from time to time about updating your personal
              information to provide you with features that we believe may
              benefit/interest you.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"><b>Children’s Privacy </b></span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >Shish Cyclothon is not intentionally designed for or directed at
              persons less than 18 years of age. Shish Cyclothon does not
              knowingly permit any person who is under 18 years of age to register
              with the Services or to provide any other personally identifying
              information. If Shish Cyclothon becomes aware that any personally
              identifiable information of persons less than 18 years of age has
              been collected on Shish Cyclothon Services without verified parental
              consent, then Shish Cyclothon will take the appropriate steps to
              delete any such information and notify the parent.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >However, we consider it the responsibility of parents to monitor
              their children’s use of our services. Nevertheless, it is our policy
              not to collect and process any personal information from children
              under 18 years of age or offer to send any promotional materials to
              persons in that category. Shish Cyclothon does not seek or intend to
              seek or receive any personal information from children. Should a
              parent or guardian have reasons to believe that a minor has provided
              Shish Cyclothon with personal information without their prior
              consent, please contact our customer support team to ensure that the
              personal information is removed from the Shish Cyclothon
              Services.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b>Security and compliance with laws </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              ><b>How we protect your personal data </b></span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >We take appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. These include internal reviews of our data
              collection, storage and processing practices and security measures,
              including appropriate encryption and physical security measures to
              guard against unauthorized access to systems where we store personal
              data. All information gathered on the website is securely stored
              within the Shish Cyclothon controlled database. The database is
              stored on servers secured behind a firewall; access to the servers
              is password-protected and is strictly limited. However, as effective
              as our security measures are, no security system is impenetrable. If
              you know or have reason to believe that your Shish Cyclothon account
              credentials have been lost, stolen, altered, or otherwise
              compromised or in case of any actual or suspected unauthorized use
              of your account, please contact us by contacting our customer
              support team.
            </span></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"><b>Changes to privacy notice</b></span></font
          ></font
        ><font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"> </span></font
        ></font>
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <font face="Calibri, serif"
          ><font size="2" style="font-size: 11pt"
            ><span lang="en-US"
              >The internetrnet is an ever-evolving medium. We may alter our
              Privacy Policy from time to time to incorporate necessary changes in
              technology, applicable laws or any other variant. In any case, we
              reserve the right to change (at any point of time) the terms of this
              Privacy Policy or the Terms of Use. Any changes we make will be
              effective immediately on notice, which we may give by posting the
              new policy on the website. Your use of the website or Services
              offered through the website after such notice will be deemed
              acceptance of such changes. We may also make reasonable efforts to
              inform you via electronic mail. In any case, you are advised to
              review this Privacy Policy periodically on the website to ensure
              that you are aware of the latest version.</span
            ></font
          ></font
        >
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
      <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
    </body>
  </html>
  `;
  return <div className="m-4" dangerouslySetInnerHTML={{ __html: policy }} />;
}

export default PrivacyPolicy;
