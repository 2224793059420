import React from "react";
import { Images } from "../../../constants/images";

const OurCause = () => {
  return (
    <div className="md:pl-[20px] lg:pl-[80px] py-[20px] lg:py-[96px] flex flex-col lg:flex-row items-center relative">
      <div className="w-full lg:w-[30%]">
        <div className="h-auto">
          <div className="flex items-center mb-[10px] justify-start -ml-[40px]">
            <img src={Images?.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-[#1A1917] text-3xl font-normal">OUR</span>
          </div>
        </div>

        <div className="mx-[15px] text-left">
          <span className=" font-extrabold text-2xl sm:text-3xl lg:text-[40px] gradient_txt">
            2024 CAUSE
          </span>
          <div className="mb-5 text-[#3F3F3F] sm:text-[22px] font-normal mt-[20px]">
            <p className="mb-5 text-[20px] sm:text-[35px] font-medium text-black">
              Fostering inclusion for vulnerable populations
            </p>
            <span>
              Ride with a purpose, showcasing your support for the community,
              and actively contribute to making a positive impact. Your
              commitment not only propels you forward but also fuels meaningful
              change within the community.
            </span>
            <img className="md:block hidden mt-2" alt="" src={Images.pledge} />
          </div>
        </div>
        <img className="md:hidden block" alt="" src={Images.pledge} />
      </div>
      <div className="w-full lg:w-[70%] mt-[20px] lg:mt-0">
        <img
          src={Images.ourCause}
          className="h-auto w-full"
          alt="Women's Group Discussion "
        />
      </div>
    </div>
  );
};

export default OurCause;
