import axios from "axios";
import { toast } from "react-toastify";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Input from "../input";
import Select from "../select";
import InputTitle from "../inputTitle";
import { Images } from "../../constants/images";

const startingLocation = [
  { name: "Surat" },
  { name: "BAPS Mandir, Bharuch" },
  { name: "Sussen Circle, Vadodara" },
];

export const initFormObject = {
  name: {
    value: "",
    regex: /.{2,}/,
    isValid: true,
    errorMessage: "please enter valid name !",
  },
  email: {
    value: "",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    isValid: true,
    errorMessage: "please enter valid email !",
  },
  mobileNo: {
    value: "",
    maxLength: 10,
    regex: /^\d{10}$/,
    isValid: true,
    errorMessage: "please enter valid mobile no !",
  },
  address: {
    value: "",
    regex: /.{2,}/,
    isValid: true,
    errorMessage: "please enter valid address !",
  },
  pincode: {
    value: "",
    inputType: "number",
    maxLength: 6,
    regex: /^[1-9][0-9]{5}$/,
    isValid: true,
    errorMessage: "please enter valid pincode !",
  },
  state: {
    value: "",
    regex: /.{2,}/,
    isValid: true,
    errorMessage: "please enter state !",
  },
  city: {
    value: "",
    regex: /.{2,}/,
    isValid: true,
    errorMessage: "please enter city !",
  },
  area: {
    value: "",
    regex: "",
    isValid: true,
    errorMessage: "",
  },
  sharedLocation: {
    value: startingLocation[0].name,
    regex: "",
    isValid: true,
    errorMessage: "",
  },
  distanceTravelled: {
    value: "10",
    regex: "",
    isValid: true,
    errorMessage: "",
  },
};

export default function RegisterModal({ isOpen, closeModal }) {
  const [form, setForm] = useState(initFormObject);

  const [selectedOption, setSelectedOption] = useState("option1");
  const [area, setArea] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setForm({
      ...form,
      distanceTravelled: {
        ...form.distanceTravelled,
        value: selectedOption !== "option1" ? 10 : 20,
      },
    });
  };

  const handlePinChange = async ({ type, value }) => {
    if (value?.length === 6) {
      const addressDetail = await axios.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      if (
        addressDetail?.data?.[0].Status != "Error" &&
        addressDetail?.data?.[0]?.PostOffice?.[0]
      ) {
        setForm({
          ...form,
          [type]: {
            ...form[type],
            isValid: true,
            value,
          },
          state: {
            ...form.state,
            value: addressDetail.data[0].PostOffice[0].State,
            isValid: true,
            errorMessage: "",
          },
          city: {
            ...form.city,
            value: addressDetail.data[0].PostOffice[0].District,
            isValid: true,
            errorMessage: "",
          },
        });
        setArea(
          addressDetail.data[0].PostOffice.map((el) => ({
            name: el.Name,
          }))
        );
      }
    } else {
      setForm({
        ...form,
        [type]: {
          ...form[type],
          isValid: false,
          value,
          errorMessage: "Please enter valid pincode !",
        },
        state: {
          ...form.state,
          value: "",
        },
        city: {
          ...form.city,
          value: "",
        },
      });
    }
  };

  const handleInputChange = ({ type, value }) => {
    setForm({
      ...form,
      [type]: {
        ...form[type],
        isValid: true,
        value,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      let isValid = true;

      Object.keys(form).forEach((fieldName) => {
        const { value, regex } = form[fieldName];

        if (regex) {
          const pattern = new RegExp(regex);

          if (!pattern.test(value)) {
            isValid = false;

            setForm((prevForm) => ({
              ...prevForm,
              [fieldName]: {
                ...prevForm[fieldName],
                isValid: false,
              },
            }));
          }
        }
      });

      if (isValid) {
        const body = {
          id: 0,
          address: form.address.value,
          area: form.area.value,
          city: form.city.value,
          email: form.city.value,
          mobileNo: form.mobileNo.value,
          name: form.name.value,
          pinCode: form.pincode.value,
          startVenue: form.sharedLocation.value,
          state: form.state.value,
          travellingDistance: form.distanceTravelled.value,
        };
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/user/saveUser`,
          body
        );
        setForm(initFormObject);
        toast.success("Form succesfully submitted!", {
          position: "bottom-right",
        });
        closeModal();
      } else {
      }
    } catch (err) {
      alert("something went wrong 500");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            closeModal();
            setForm(initFormObject);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full 2xl:w-[70%] transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="grid lg:grid-cols-12 items-center">
                    <img
                      alt="modal_banner"
                      src={Images.modelBanner}
                      className="object-contain col-span-5 h-full hidden lg:block"
                    />
                    <div className="col-span-7 md:mx-10">
                      <div className="relative">
                        <h6 className="mb-8 text-[25px] text-center font-extrabold gradient_txt">
                          Participant Details
                        </h6>
                        <div
                          className="absolute top-0 right-0 cursor-pointer"
                          onClick={() => {
                            closeModal();
                            setForm(initFormObject);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="md:mb-10">
                        <div className="mb-4 flex flex-col sm:flex-row items-center">
                          <InputTitle title="Name *" />
                          <Input
                            type="name"
                            placeholder="Enter Name"
                            value={form.name.value}
                            formData={form.name}
                            handleInputChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 flex flex-col sm:flex-row items-center">
                          <InputTitle title="Email *" />
                          <Input
                            type="email"
                            placeholder="Enter Email"
                            value={form.email.value}
                            formData={form.email}
                            handleInputChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 flex flex-col sm:flex-row items-center">
                          <InputTitle title="Mobile Number*" />
                          <Input
                            placeholder="Enter Mobile No"
                            type="mobileNo"
                            value={form.mobileNo.value}
                            formData={form.mobileNo}
                            handleInputChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 flex flex-col sm:flex-row items-center">
                          <InputTitle title="Address *" />
                          <textarea
                            className="w-full py-2.5 px-3 rounded-[8px] bg-[#EFEFEF]"
                            placeholder="Address"
                            rows={3}
                            value={form.address.value}
                            onChange={(e) =>
                              handleInputChange({
                                type: "address",
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-6">
                          <div className="flex flex-col sm:flex-row items-center">
                            <InputTitle title="Pincode" />
                            <Input
                              placeholder="Pincode"
                              type="pincode"
                              value={form.pincode.value}
                              formData={form.pincode}
                              handleInputChange={handleInputChange}
                              handleChange={handlePinChange}
                            />
                          </div>
                          <div className="flex flex-col sm:flex-row items-center">
                            <InputTitle title="State" />
                            <Input
                              type="state"
                              placeholder="State"
                              value={form.state.value}
                              formData={form.state}
                              handleInputChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-6">
                          <div className="flex flex-col sm:flex-row items-center">
                            <InputTitle title="City" />
                            <Input
                              placeholder="City"
                              type="city"
                              value={form.city.value}
                              formData={form.city}
                              handleInputChange={handleInputChange}
                            />
                          </div>
                          <div className="flex flex-col sm:flex-row items-center z-10">
                            <InputTitle title="Area" />
                            <Select
                              placeholder="Area"
                              selected={{
                                name: form.area.value,
                              }}
                              options={area}
                              handleChange={(e) => {
                                setForm({
                                  ...form,
                                  area: {
                                    ...form.area,
                                    value: e.name,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-6">
                          <div className="flex flex-col sm:flex-row items-center">
                            <InputTitle title="Starting Location*" />
                            <Select
                              selected={{
                                name: form.sharedLocation.value,
                              }}
                              options={startingLocation}
                              handleChange={(e) => {
                                setForm({
                                  ...form,
                                  sharedLocation: {
                                    ...form.sharedLocation,
                                    value: e.name,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="flex items-center 2xl:justify-around">
                            <div className="flex items-center gap-x-5 justify-center">
                              <label className="inline-flex items-center cursor-pointer">
                                <input
                                  type="radio"
                                  value="option1"
                                  checked={selectedOption === "option1"}
                                  onChange={handleOptionChange}
                                  className="form-radio h-5 w-5 text-[#007F3D] cursor-pointer"
                                />
                                <span className="ml-2 text-gray-700">
                                  10 Kms
                                </span>
                              </label>
                              <label className="inline-flex items-center cursor-pointer">
                                <input
                                  type="radio"
                                  value="option2"
                                  checked={selectedOption === "option2"}
                                  onChange={handleOptionChange}
                                  className="form-radio h-5 w-5 text-[#007F3D] cursor-pointer"
                                />
                                <span className="ml-2 text-gray-700">
                                  20 Kms
                                </span>
                              </label>
                            </div>
                          </div>
                          {/* <div className="flex flex-col sm:flex-row items-center">
                            <InputTitle title="Area" />
                            <Input />
                          </div> */}
                        </div>
                      </div>
                      <button
                        className="w-full py-1.5 text-[22px] text-center text-white rounded-[10px]"
                        style={{
                          background:
                            "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
                        }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
