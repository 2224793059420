import React from "react";

const CommonBanner = ({ img }) => {
  return (
    <div
      className="pl-4 md:pl-20 py-10 relative h-auto sm:h-[calc(100vw-60vw)] flex flex-col justify-center "
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <h1 className=" text-[32px] md:text-[90px] text-white text-left font-bold">
        About Us
      </h1>
      <p className="text-[20px] md:text-[35px] text-white font-medium">
        Seeding the Values of Health & <br /> Sustainability
      </p>
    </div>
  );
};

export default CommonBanner;
