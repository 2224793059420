import { Form, Input, Modal, Spin, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";

function PassDownloadModal({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const validateEmail = (_, value) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value && !emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address");
    }

    return Promise.resolve();
  };

  const downloadPass = () => {
    // console.log();
    setIsLoading(true);
    let email = form.getFieldValue("email");
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/user/getPassUrl?email=${email}`
      )
      .then(async (res) => {
        console.log(res.data.data);
        const response = await fetch(res.data.data);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `Entry_pass.jpg`;
        link.click();
        URL.revokeObjectURL(blobUrl);
        setIsLoading(false);
        form.resetFields();
        onClose();
      })
      .catch((error) => {
        notification.open({
          description: "Email ID is not registerd with us!",
          placement: "bottomRight",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      footer={null}
      title="Download Certificate"
    >
      <Form
        form={form}
        onFinish={() => {
          downloadPass();
        }}
      >
        <h1 className="mb-1 ml-1">Email ID</h1>
        <Form.Item
          name={"email"}
          rules={[
            {
              required: true,
              message: "Please enter a valid email address",
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input
            name="email"
            inputMode="email"
            title="Email Id"
            className="h-[40px]"
            placeholder="Enter email id"
          />
        </Form.Item>
        <div className="flex flex-1 justify-center mt-6">
          <button
            disabled={isLoading}
            style={{
              background: "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
              opacity: 1,
            }}
            className="px-5  text-white w-[150px] py-2 rounded-lg font-bold "
            type="html"
          >
            {isLoading ? <Spin /> : "Download"}
          </button>
        </div>
      </Form>
    </Modal>
  );
}

export default PassDownloadModal;
