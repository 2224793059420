// ImageUploadComponent.jsx
import { useState } from "react";
import { Images } from "../constants/images";

const ImageUploadInput = ({
  handleUploadImage,
  type,
  placeholder,
  formData,
  handleInputChange,
}) => {
  const [image, setImage] = useState(null);

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div
      className="w-full flex flex-col items-center justify-center"
      style={{
        height: formData?.value && "100px",
        width: formData?.value && "100px",
        alignItems: formData?.value && "start",
      }}
    >
      <label
        htmlFor="imageUpload"
        className="relative group w-full h-[52px] bg-white rounded-[8px] border border-gray-300 flex items-center justify-start cursor-pointer hover:bg-gray-100"
        style={{
          height: formData?.value && "100px",
          width: formData?.value && "100px",
          justifyContent: "center",
          alignItems: formData?.value && "start",
        }}
      >
        {formData?.value ? (
          <div className="relative">
            <img
              src={image}
              alt="Selected"
              className="w-full h-[100px] object-cover rounded-[8px] z-2"
            />
            <img
              alt=""
              src={Images.cross}
              className="absolute top-0 right-0 w-[30px] transition cursor-pointer z-10"
              onClick={(e) => {
                e.stopPropagation();
                handleInputChange({ type, value: "" });
              }}
            />
          </div>
        ) : (
          <>
            <span className="text-gray-500 w-full text-center">
              {placeholder || "Select.."}
            </span>
            <input
              type="file"
              id="imageUpload"
              accept="image/png, image/jpeg, image/jpg"
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-5"
              onChange={(e) => {
                handleImageUpload(e);
                const file = e.target.files[0];
                const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
                if (file.size > maxSizeInBytes) {
                  handleUploadImage({
                    event: e,
                    type,
                    isError: true,
                    msg: "Please select a file smaller than 5MB.",
                  });

                  e.target.value = null;
                } else {
                  if (file) {
                    const fileName = file.name;
                    const fileType = fileName.slice(
                      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
                    );
                    if (
                      fileType === "png" ||
                      fileType === "jpg" ||
                      fileType === "JPG" ||
                      fileType === "PNG"
                    ) {
                      handleUploadImage({ event: e, type });
                    } else {
                      handleUploadImage({
                        event: e,
                        type,
                        isError: true,
                        msg: "Please select a PNG or JPG file only",
                      });
                      e.target.value = null;
                    }
                  }
                }
              }}
            />
          </>
        )}
      </label>
      {!formData?.isValid && (
        <span className="w-full text-left mt-2 text-[13px] text-red-500">
          {formData?.errorMessage}
        </span>
      )}
    </div>
  );
};

export default ImageUploadInput;
