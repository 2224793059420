import React from "react";
import { Images } from "../../constants/images";
import { useNavigate } from "react-router-dom";

function RegisterWithUs({ openModal, img }) {
  const navigation = useNavigate();
  return (
    <div className="relative">
      <img
        alt="banner_img"
        src={Images.coupleBiker}
        className="w-full object-fill  h-[120px]  sm:h-auto"
      />
      <div className="absolute top-[23px] sm:top-[5.281vw] left-0 right-0 mx-auto">
        <h3 className="text-[12px] sm:text-[16px] md:text-[22px] xl:text-[45px] text-white text-center font-semibold">
          Join us in this Cyclothon journey towards a healthier <br /> and
          greener future!
        </h3>
        <div className="flex items-center justify-center mt-[6px] md:mt-[20px]">
          <button
            className="py-[6px] lg:py-[10px] px-[20px] lg:px-[100px] text-[#242424] text-[12px] sm:text-[16px] md:text-[22px] lg:text-[30px] rounded-full bg-white font-semibold cursor-pointer"
            onClick={() => {
              navigation("/register");
            }}
          >
            REGISTER NOW
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterWithUs;
