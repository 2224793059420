import React from "react";
import { Images } from "../../constants/images";

function SupportSection() {
  return (
    <>
      <div className="relative">
        <img
          src={Images?.supportSectionBgImage}
          alt=""
          className="block mx-auto h-[400px] md:h-auto"
        />
        <div className="absolute top-[36%] md:top-1/2 left-1/2 w-full transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
          <span className="text-[14px] px:[10px] md:text-[26px] lg:text-[36px] desktop:text-[45px] uppercase font-bold block">
            Facilities and Support Provided
          </span>
          <div className="flex items-center flex-row flex-wrap justify-center mt-4 h-[210px] md:h-[300px] lg:h-[450px] px-[30px] md:px-0">
            <div className="flex justify-between w-full md:w-auto">
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.refreshment}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Refreshment STATION
                </span>
              </div>
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.medical}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Medical Support 
                </span>
              </div>
            </div>

            <div className="flex justify-between w-full md:w-auto">
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.massage}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Physiotherapist 
                </span>
              </div>
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.collaboration}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Support Crew 
                </span>
              </div>
            </div>

            <div className="flex justify-between w-full md:w-auto">
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.hotel}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Accommodation 
                </span>
              </div>
              <div className="flex flex-col md:hidden items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.sightSeeing}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  SIGHTSEEING
                </span>
              </div>
            </div>

            <div className="flex justify-between w-full md:w-auto">
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.dinner}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Healthy mEALS
                </span>
              </div>
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.liveMusic1}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  Live MUSIC
                </span>
              </div>
            </div>

            <div className="flex justify-between w-full md:w-auto">
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.eveningActivity}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  ENGAGING EVENING ACTIVITIES
                </span>
              </div>
              <div className="flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.inspirationSpeech}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  INSPIRING sPEECHES
                </span>
              </div>
            </div>

            <div className="flex justify-between w-full md:w-auto">
              <div className="hidden md:flex flex-col items-center w-[110px] md:w-[150px] lg:w-[220px] xl:w-[310px]">
                <img
                  src={Images?.sightSeeing}
                  alt=""
                  className="w-[30px] md:w-[40px] lg:w-[90px] h-[30px] md:h-[40px] lg:h-[90px] mr-2"
                />
                <span className="mt-[6px] lg:mt-[30px] uppercase text-[12px] lg:text-[20px]">
                  SIGHTSEEING
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportSection;
