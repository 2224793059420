import React, { useEffect, useState } from "react";
import { Images } from "../../../constants/images";

const ambassadorCard = [
  {
    title: `<p>Designated </br> Dignitaries</p>`,
    img: Images.cause1,
  },
  {
    title: `<p>Educational </br> Institution</p>`,
    img: Images.cause2,
  },
  {
    title: `<p>Non-Governmental </br> Organizations</p>`,
    img: Images.cause3,
  },
  {
    title: `<p>Associations</p>`,
    img: Images.cause4,
  },
  {
    title: `<p>Celebrities</p>`,
    img: Images.cause5,
  },
];

const ambassadorMobile1 = [
  {
    title: `<p>Designated </br> Dignitaries</p>`,
    img: Images.cause1,
  },
  {
    title: `<p>Educational </br> Institution</p>`,
    img: Images.cause2,
  },
  {
    title: `<p>Associations</p>`,
    img: Images.cause4,
  },
];
const ambassadorMobile2 = [
  {
    title: `<p>Non-Governmental </br> Organizations</p>`,
    img: Images.cause3,
  },
  {
    title: `<p>Celebrities</p>`,
    img: Images.cause5,
  },
];

const Ambassadors = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("screenSizescreenSizescreenSize", screenSize);
  return (
    <div>
      {screenSize > 1024 ? (
        <>
          <div className="md:bg-black">
            <h6 className="py-20 uppercase text-3xl text-center text-white font-bold">
              Cause Ambassadors
            </h6>
            <div className="md:pb-32 hidden md:block"></div>
          </div>
          <div className="-mt-40 grid md:grid-cols-5 content-between gap-5">
            {ambassadorCard.map((el) => (
              <div className="flex flex-col justify-between items-center ">
                <h6
                  className="mb-2 text-[24px] text-center md:text-white"
                  dangerouslySetInnerHTML={{
                    __html: el.title,
                  }}
                />
                <div
                  className={`p-[60px] bg-white sm:h-[150px] md:h-[200px] lg:h-[250px] md:w-[200px] lg:w-[250px] shadow-[0px_4px_22.6px_-4px_rgba(0,0,0,0.25)]`}
                >
                  <img alt="" src={el.img} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="bg-black">
          <div>
            <h6
              className={` py-20 ${
                screenSize <= 425 ? "pt-10 pb-5" : ""
              } uppercase text-xl sm:text-3xl text-center text-white font-bold`}
            >
              Cause Ambassadors
            </h6>
            <div className="md:pb-5 hidden md:block"></div>
          </div>
          <div
            className={`sm:mt-10 pb-4 sm:pb-10  ${
              screenSize <= 375 ? "mt-1 pb-1 mx-2" : ""
            }  grid grid-cols-3 content-between gap-3 sm:mx-11 ${
              screenSize <= 425 ? "mx-12" : ""
            }`}
          >
            {ambassadorMobile1.map((el) => (
              <div className="flex flex-col justify-between items-center ">
                <div
                  className={`${screenSize <= 425 ? "p-[25px]" : "p-[60px]"} ${
                    screenSize <= 375 ? "p-[20px]" : ""
                  } bg-white ${
                    screenSize <= 425 ? "w-[100px] h-[100px]" : ""
                  } ${screenSize <= 375 ? "w-[75px] h-[70px] " : ""} ${
                    screenSize <= 280 ? "w-[58px] h-[55px] " : ""
                  } ${
                    screenSize <= 280 ? "p-[16px] " : ""
                  } md:w-[200px] md:h-[200px] sm:w-[150px] sm:h-[150px] shadow-[0px_4px_22.6px_-4px_rgba(0,0,0,0.25)]`}
                >
                  <img alt="" src={el.img} />
                </div>
                <h6
                  className={`mb-2 ${
                    screenSize <= 425 ? "text-[12px]" : " text-[24px]"
                  } ${
                    screenSize <= 280 ? "text-[08px]" : ""
                  } text-center text-white`}
                  dangerouslySetInnerHTML={{
                    __html: el.title,
                  }}
                />
              </div>
            ))}
          </div>
          <div
            className={`sm:mt-10 pb-10 ${
              screenSize <= 375 ? "mt-1 mx-7" : ""
            } grid grid-cols-2 content-between gap-2 sm:mx-24 ${
              screenSize <= 375 ? "mx-16" : ""
            } ${screenSize <= 425 ? "mx-24" : ""} `}
          >
            {ambassadorMobile2.map((el) => (
              <div
                className={`flex flex-col justify-between items-center ${
                  screenSize <= 425 ? "mx-6" : ""
                }`}
              >
                <div
                  className={`${
                    screenSize <= 425 ? "p-[25px]" : "p-[60px]"
                  } bg-white ${
                    screenSize <= 425 ? "w-[100px] h-[100px]" : ""
                  } ${screenSize <= 375 ? "w-[75px] h-[70px]" : ""} ${
                    screenSize <= 280 ? "w-[58px] h-[55px] " : ""
                  } ${
                    screenSize <= 280 ? "p-[16px] " : ""
                  } md:w-[200px] md:h-[200px]  sm:w-[150px] sm:h-[150px] shadow-[0px_4px_22.6px_-4px_rgba(0,0,0,0.25)]`}
                >
                  <img alt="" src={el.img} />
                </div>
                <h6
                  className={`mb-2 ${
                    screenSize <= 425 ? "text-[12px]" : " text-[24px]"
                  } ${
                    screenSize <= 280 ? "text-[08px]" : ""
                  } text-center text-white`}
                  dangerouslySetInnerHTML={{
                    __html: el.title,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Ambassadors;
