import { Route, Routes, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import Layout from "./Pages/layout";
import Home from "./Pages/home/home";
import Registration from "./Pages/registration";
import ShishCyclothon2024 from "./Pages/shishCyclothon2024";
import AboutUs from "./Pages/aboutUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./components/policy/PrivacyPolicy";
import TermsOfUse from "./components/policy/termsOfUse";
import { useEffect } from "react";
import Media from "./Pages/media";

function App() {
  let navigate = useNavigate();

  const history = {
    navigate: null,
    location: null,
  };

  useEffect(() => {
    history.navigate = navigate;
    if (process.env.REACT_APP_ENV != "dev") {
      console.log = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  }, []);

  return (
    <div className="overflow-hidden">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/shishCyclothon" element={<ShishCyclothon2024 />} />

          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/media" element={<Media />} />
          <Route path="/*" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsOfUse />} />
        </Routes>
      </Layout>
      <ToastContainer />
    </div>
  );
}

export default App;
