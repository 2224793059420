import React from "react";
import { Images } from "../../constants/images";
import { useNavigate, useNavigation } from "react-router-dom";

function Footer() {
  const navigation = useNavigate();
  return (
    <div className="bg-[#1A1917]   px-[40px] py-2">
      <div className="w-full  bg-[#1A1917] py-[50px] flex  flex-col lg:flex-row lg:items-center justify-between">
        <img
          src={Images.shishCyclothonLogo}
          alt=""
          className="md:w-[200px] lg:w-[250px] xl:w-[320px] "
        />
        <div className="flex justify-between  flex-col mt-10 lg:mt-0 lg:flex-row md:w-[75%]">
          <div className="text-white xl:w-[30%]">
            <h3 className="font-bold mb-[10px] md:mb-[30px]">ADDRESS</h3>
            <p className="text-[#807D79]">
              SHISH FOUNDATION SY850/1,SUBPL-C PL-11-12-13B,1 FLR,SURYA, PURMILL
              COMP BH GITAJALI ASHA SUBSTATION, VARACHHAROAD, Surat, Gujarat,
              India, 395006
            </p>
            {/* <p>Registration</p>
          <p>Shish Cyclothon 2024</p>
          <p>Photos</p>
          <p>Blogs</p>
          <p>About Us</p> */}
          </div>

          <div className="text-white w-auto xl:w-[15%]  mt-[20px] md:mt-0">
            <h3 className="font-bold mb-[10px] md:mb-[30px] mt-10 lg:mt-0">
              GET IN TOUCH
            </h3>
            <p
              className=" text-[12px] md:text-[16px] whitespace-nowrap cursor-pointer"
              onClick={() => {
                const phoneNumber = "+91 7861880492";
                window.location.href = `tel:${phoneNumber}`;
              }}
            >
              +91 7861880492
            </p>
            <p
              className="mr-4  text-[12px] md:text-[16px] cursor-pointer"
              onClick={() => {
                const email = "info@shishcyclothon.com";
                window.location.href = `mailto:${email}`;
              }}
            >
              info@shishcyclothon.com
            </p>
            <div className="flex mt-[20px]">
              <a
                href="https://www.instagram.com/shishcyclothon/"
                target="_blank"
                className="mr-[10px]"
              >
                <img
                  src={Images.instagram}
                  alt="Instagram"
                  className="w-[15px] md:w-[30px] h-[15px] md:h-[30px]"
                />
              </a>
              <a
                href="https://www.facebook.com/shishcyclothon0"
                target="_blank"
                className="mr-[10px]"
              >
                <img
                  src={Images.facebook}
                  alt="Facebook"
                  className="w-[15px] md:w-[30px] h-[15px] md:h-[30px]"
                />
              </a>
              <a
                href="https://twitter.com/shishcyclothon?t=7enX-6eHAho1XM1bAMtPEQ&s=09"
                target="_blank"
              >
                <img
                  src={Images.twitter}
                  alt="Twitter"
                  className="w-[15px] md:w-[30px] h-[15px] md:h-[30px]"
                />
              </a>
            </div>
          </div>

          <div className="text-white w-auto xl:w-[30%] mt-[20px] md:mt-0">
            <h3 className="font-bold mb-[10px] md:mb-[30px] mt-10 lg:mt-0">
              ABOUT SHISH CYCLOTHON
            </h3>
            <p className="text-[#807D79]">
              Shish Cyclothon is committed to promoting sustainability and
              health where wheels turn not only for competition but also for a
              noble cause.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div
          onClick={() => {
            navigation("/termsandconditions");
          }}
          className="text-white underline cursor-pointer"
        >
          Terms & Conditions |{" "}
        </div>
        <div
          onClick={() => {
            navigation("/privacypolicy");
          }}
          className="text-white underline ml-[4px] cursor-pointer"
        >
          {" "}
          Privacy Policy
        </div>
      </div>
    </div>
  );
}

export default Footer;
