import React from "react";
import { Images } from "../../constants/images";

function InspirationSection() {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex justify-center items-center w-full lg:w-[30%]">
        <img
          src={Images.modiSaab}
          alt="PM - Narendra Modi"
          className="hidden sm:block max-h-[490px]"
        />
      </div>
      <div className="bg-[#1A1917] w-full lg:w-[70%] pt-[20px] lg:pt-[60px] pl-[20px] lg:pl-[95px] pr-[20px] lg:pr-[130px]">
        <div className="h-auto">
          <div className="flex items-center mb-[10px]">
            <img src={Images?.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-white text-3xl font-normal">OUR</span>
          </div>
          <span className="ml-[40px] md:ml-[60px] lg:ml-[100px] font-extrabold text-2xl lg:text-[40px] gradient_txt">
            INSPIRATION - LIFE
          </span>
        </div>

        <div className="md:text-[22px] text-white ml-0 lg:ml-[30px] mt-[50px]">
          <span className="font-bold">
            “…Together let us make our planet better. Let us Act Together. It is
            time for Action. Action for Life, Action for Lifestyle For
            Environment (LiFE).”
          </span>
          <span className="flex items-center justify-end mt-[20px] font-semibold">
            <hr className="h-[3px] w-[20px] bg-white mr-[8px]" /> PM Narendra
            Modi
          </span>
          <span>
            Inspired by the visionary words of our Honorable PM Narendra Modi,
            we are thrilled to announce the initiation of an annual cyclothon
            event, promoting health, sustainability, and community spirit. Shish
            Cyclothon is our way of contributing to Modiji's vision of a greener
            and healthier tomorrow.
          </span>
        </div>
      </div>
      <img
        src={Images.modiSaab}
        alt="modi image"
        className="block sm:hidden object-contain max-h-[262px]"
      />
    </div>
  );
}

export default InspirationSection;
