import React, { useEffect, useState } from "react";
import { Images } from "../../constants/images";
import axios from "axios";
import { notification } from "antd";
import "./index.css";
import VideoModal from "../../components/modal/videoModal";

function Media() {
  const [activeTab, setActiveTab] = useState(1);
  const [mediaData, setMediaData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  console.log("mediaData", mediaData);

  useEffect(() => {
    if (activeTab === 1) getMediaData("coverage");
    if (activeTab === 2) getMediaData("release");
  }, [activeTab]);

  const getMediaData = (type) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/user/getAllNew?mediaType=${type}&type=shish`
      )
      .then(async (res) => {
        setMediaData(res?.data?.data);
      })
      .catch((error) => {
        notification.open({
          description: "Something went wrong",
          placement: "bottomRight",
          type: "error",
        });
      });
  };

  return (
    <div>
      <div className="relative">
        <img
          alt="Shish cyclothon - 2024"
          src={Images.mediaBanner}
          className="w-full object-fill"
        />
        <div className="absolute top-[13.281vw] left-0 right-0 mx-auto">
          <h1 className="text-[20px] sm:text-[30px] md:text-[40px] lg:text-[90px] text-white text-center font-extrabold">
            News & Reports
          </h1>
        </div>
      </div>
      <div className="px-[16px] lg:px-[80px]">
        <div className="mt-[20px] sm:mt-[80px]">
          <div className="flex flex-row justify-between text-center">
            {/* <div className='text-bg-image w-[50%]'> */}
            <span
              className={`text-[20px] sm:text-[26px] lg:text-[40px] w-[50%] mr-1 sm:mr-0 font-bold uppercase cursor-pointer flex justify-center items-center ${
                activeTab === 1 ? "text-[#007B97] text-bg-image" : "text-black"
              }`}
              onClick={() => setActiveTab(1)}
            >
              MEDIA COVERAGE
            </span>
            {/* </div> */}
            {/* <div className='text-bg-image w-[50%]'> */}
            <span
              className={`text-[20px] sm:text-[26px] lg:text-[40px] w-[50%] font-bold uppercase cursor-pointer flex justify-center items-center ${
                activeTab === 2 ? "text-[#007B97] text-bg-image" : "text-black"
              }`}
              onClick={() => setActiveTab(2)}
            >
              MEDIA RELEASES
            </span>
            {/* </div> */}
          </div>

          <hr
            className="w-full h-[15px]"
            style={{
              background:
                "linear-gradient(90deg, #007A98 4.87%, #007A98 27.09%, #00A593 98.15%)",
            }}
          />

          <div className="mb-[30px] sm:mb-[150px]">
            {mediaData?.length
              ? mediaData
                ?.filter((item) => item.status === true)
                ?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-[50px] md:h-[330px] flex flex-col md:flex-row items-center"
                    >
                      <div className="w-full md:w-[35%] flex justify-center items-center mb-4 md:mb-0 md:mr-4 lg:mr-12 relative">
                        {/* <img
                            src={Images.triangle}
                            className="h-[330px] sm:w-[50px] 2xl:w-[100px] hidden md:block"
                            alt=""
                          /> */}
                        <img
                          src={item?.image_url}
                          className={`h-[150px] md:h-[200px] w-[75%] sm:w-[50%] md:w-[100%] lg:w-[400px] 2xl:w-[475px] ${item.image_video_type == "VIDEO" ? "cursor-pointer" : ""}`}
                          alt=""
                          onClick={item.image_video_type == "VIDEO" ? () => {
                            setConfirm(true);
                            setVideoURL(item?.url);
                            setVideoTitle(item?.title);
                          } : () => { }}
                        />
                        {item.image_video_type == "VIDEO" && (
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <img
                              src={Images.youTubeLogo}
                              className="h-8 sm:h-12 w-12 sm:w-20 cursor-pointer"
                              alt="YouTube Logo"
                              onClick={() => {
                                setConfirm(true);
                                setVideoURL(item?.url);
                                setVideoTitle(item?.title);
                              }}
                            />
                          </div>
                        )}
                        {/* /> */}
                      </div>
                      <div
                        className="w-full md:w-[65%] h-full flex flex-col justify-center items-center py-3 md:py-0 px-[12px] md:px-[30px] text-white  text-[20px] lg:text-[24px]"
                        style={{
                          background:
                            "linear-gradient(180deg, #007A98 0%, #00A593 100%)",
                        }}
                      >
                        <div className="description-container w-full text-left">
                          <p className="description">{item.description}</p>
                        </div>
                        <div className="flex justify-between w-full mt-[50px]">
                          <p>SOURCE : {item?.source}</p>
                          <p>
                            {new Date(item?.created_date).getDate()}{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              month: "short",
                            }).format(new Date(item?.created_date))}{" "}
                            {new Date(item?.created_date).getFullYear()}
                          </p>
                        </div>
                        <p
                          onClick={
                            item.image_video_type
                              ? () => {
                                setConfirm(true);
                                setVideoURL(item?.url);
                                setVideoTitle(item?.title);
                              }
                              : () => window.open(item?.url, "_blank")
                          }
                          className="text-left w-full cursor-pointer font-semibold mt-[24px]"
                        >
                          {item.image_video_type
                            ? "Watch Now"
                            : "Read More >"}
                        </p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <VideoModal
        confirm={confirm}
        setConfirm={setConfirm}
        closable={true}
        url={videoURL}
        title={videoTitle}
      />
    </div>
  );
}

export default Media;
