import React, { useEffect, useState } from "react";
import { Images } from "../../constants/images";

function QuoteSection({ screenSize }) {
  console.log(screenSize);
  return (
    <div className="bg-[#F1F6FF] py-[10px] sm:py-[50px] flex justify-center items-center">
      {screenSize < 450 ? (
        <img
          src={Images.quoteMobile}
          alt="quote"
          className="w-[95%] sm:w-[80%]"
        />
      ) : (
        <img src={Images.quote} alt="quote" className="w-[95%] sm:w-[80%]" />
      )}
    </div>
  );
}

export default QuoteSection;
