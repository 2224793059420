import React, { useState } from "react";
import { Images } from "../../../constants/images";

function SuperHeroDayMapSection() {
  const [activeDay, setActiveDay] = useState(0);

  const day = ["Full Map", "Day 1", "Day 2", "Day 3", "Day 4"];
  const dayText = [
    {
      text: [
        {
          title: "Day 1",
          text1: "January 31, 2024",
          text2: "From Surat to Vadodara",
        },
        {
          title: "Day 2",
          text1: "February 1, 2024 ",
          text2: "From Vadodara to Vataman",
        },
        {
          title: "Day 3",
          text1: "February 2, 2024 ",
          text2: "From Vataman to Sarangpur",
        },
        {
          title: "Day 4",
          text1: "February 3, 2024 ",
          text2: "From Sarangpur to Surat",
        },
      ],
      img: Images.fullMapImg,
    },
    {
      text: [
        {
          title: "Date & Distance",
          text1: "January 31, 2024 | 120 Kms",
        },
        {
          title: "Meeting time & Place",
          text1: "5:00 AM, Surat",
        },
        // {
        //   title: "Inauguration Ceremony",
        //   text1: "6:00 to 7.30 am",
        // },
        {
          title: "Flag Off",
          text1: "6:30 AM, Surat",
        },
        {
          title: "Break",
          text1: "1 to 4 PM, BAPS Mandir, Bharuch",
        },
        {
          title: "Destination",
          text1: "7 PM at Gurukul, Kandari",
        },
      ],
      img: Images.day1MapImg,
    },
    {
      text: [
        {
          title: "Date & Distance",
          text1: "February 1, 2024 | 104 Kms",
        },
        {
          title: "Wake-up Time",
          text1: "4:00 AM",
        },
        {
          title: "Warmup & Stretching",
          text1: "4.30 AM",
        },
        {
          title: "Start Time",
          text1: "5:00 AM",
        },
        {
          title: "Break",
          text1: "6:30 AM at SRP Ground, Sussen Circle, Vadodara",
        },
        {
          title: "Destination",
          text1: "3 PM at Jain Vihar Dham, Indranaj",
        },
      ],
      img: Images.day2MapImg,
    },
    {
      text: [
        {
          title: "Date & Distance",
          text1: "February 2, 2024 | 109 Kms",
        },
        {
          title: "Warmup & Stretching",
          text1: "5:00 AM",
        },
        {
          title: "Start Time",
          text1: "5:30 AM",
        },
        {
          title: "Destination",
          text1: "3 PM at Hanuman Temple, Sarangpur",
        },
        {
          title: "Closing Ceremony",
          text1: "4:30 PM",
        },
        {
          title: "Award Distribution",
          text1: "5:00 PM",
        },
      ],
      img: Images.day3MapImg,
    },
    {
      text: [
        {
          title: "Date",
          text1: "February 3, 2024 ",
        },
        {
          title: "Warmup & Stretching",
          text1: "6:30 AM",
        },
        {
          title: "Ritual",
          text1: "Vishwa Swasthya Maha-yagnya from 7 to 11 AM",
        },
        {
          title: "Closing Speech",
          text1: "11:30 AM",
        },
        {
          title: "Checkout",
          text1: "1 PM",
        },
        {
          title: "Destination",
          text1: "Reach Surat by 8 PM (Return by RORO Ferry)",
        },
      ],
      img: Images.day4MapImg,
    },
  ];

  const renderText = (dayIndex) => {
    return dayText[dayIndex].text.map((info, index) => (
      <div key={index} className="mb-[10px]">
        <h3 className="text-[#007c8e] text-[18px] lg:text-[32px] sm:text-[22px]">
          {info.title}
        </h3>
        <p className="lg:text-[22px] text-[14px]">{info.text1}</p>
        {dayIndex === 0 && (
          <p className="lg:text-[22px]  text-[14px]">{info.text2}</p>
        )}
      </div>
    ));
  };

  const renderImage = (dayIndex) => {
    return <img src={dayText[dayIndex].img} alt={`Google Map Screenshot ${dayIndex ? dayIndex : ""}`} />;
  };

  return (
    <div className="mt-[20px] md:mt-[40px] lg:mt-[80px]">
      <div className="h-auto">
        <div className="flex items-center mb-[10px] justify-start ml-[20px] md:ml-[40px] lg:ml-[140px]">
          <img
            src={Images.line}
            alt=""
            className="w-[60px] sm:w-[110px] mr-[20px]"
          />
          <span className="text-[#1A1917] text-xl md:text-2xl lg:text-4xl font-normal uppercase">
            Unleash your
          </span>
        </div>
        <span className="ml-[60px] lg:ml-[200px] font-extrabold text-2xl lg:text-[40px] gradient_txt">
          SUPERPOWERS
        </span>
      </div>

      <div className="relative my-[10px] sm:my-[40px]">
        <img
          src={Images.mapBg}
          className="w-full lg:w-[65%] h-[30px] sm:h-auto"
          alt="bg-img"
        />

        <div className="absolute top-0 sm:top-[0.800vw] lg:top-0 xl:top-[0.500vw] w-[80%] lg:w-[50%] left-0 right-0 md:ml-[60px] lg:ml-[150px]">
          <div className="flex justify-between">
            {day.map((data, index) => (
              <div key={index} className="relative inline-block">
                <span
                  className={`capitalize text-[12px] sm:text-[26px] lg:text-[30px] xl:text-[36px] cursor-pointer ${index === activeDay
                    ? "gradient_txt p-1 relative"
                    : "text-white"
                    }`}
                  onClick={() => setActiveDay(index)}
                >
                  {data}
                  {index === activeDay && (
                    <span className="absolute inset-x-0 bottom-0 h-1 w-full">
                      <span className="absolute inset-0 bg-gradient-to-r from-[#0079C0] to-[#007F3D]"></span>
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row my-[36px]">
        <div className="left-side-text w-full md:w-[50%] lg:w-[40%] lg:ml-[150px]  lg:mr-[20px] sm:ml-[18px] ml-[14px]">
          {renderText(activeDay)}
        </div>
        <div className="right-side-image w-full md:w-[50%] lg:w-[60%]">
          {renderImage(activeDay)}
        </div>
      </div>
    </div>
  );
}

export default SuperHeroDayMapSection;
