import React from "react";

const Input = ({
  type,
  formData,
  placeholder,
  value,
  handleInputChange,
  handleChange,
  className,
}) => {
  return (
    <div className={`w-full flex flex-col ${className}`}>
      <input
        className="w-full py-4 px-3.5 rounded-[8px] bg-[#fff]"
        placeholder={placeholder}
        value={value}
        // type={formData?.inputType}
        maxLength={formData?.maxLength}
        onChange={(e) => {
          handleInputChange({ type, value: e.target.value });
          handleChange && handleChange({ type, value: e.target.value });
        }}
      />
      {!formData?.isValid && (
        <span className="mt-2 text-[13px] text-red-500">
          {formData?.errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
