import React, { useEffect, useState } from "react";
import { Images } from "../../constants/images";

function SuperHero() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {/* <div className='flex flex-col lg:flex-row lg:h-[714px] md:my-[130px]'>
        <div className='lg:w-4/7'>
          <img src={Images?.SuperHeroKitImage} alt="" className='w-full' />
        </div>
        <div className='lg:w-[700px] lg:relative lg:left-[-170px] mt-[30px]'>
          <div className='h-auto'>
            <div className='flex items-center mb-[10px]'>
              <img src={Images?.line} alt="" className='w-[110px] mr-[20px]' />
              <span className='text-[#1A1917] text-3xl font-normal'>SHISH</span>
            </div>
            <span className='text-[#017B91] font-extrabold text-3xl ml-[50px] lg:text-[50px]'>SUPERHERO KIT</span>
          </div>
          <div className='relative flex flex-col lg:pl-[10%] lg:left-auto lg:h-[450px] mt-[50px] justify-between'>
            <div className='flex ml-[10px] md:ml-0 items-center'>
              <img src={Images?.bicycle} alt="" className='w-[50px] lg:w-auto' />
              <span className='text-black text-lg wd:ml-[20px] text-[20px]'>BICYCLES</span>
            </div>
            <div className='flex items-center m-[10px]  md:ml-[30px]'>
              <img src={Images?.safetyGear} alt="" className='w-[50px] lg:w-auto' />
              <span className='text-black text-lg ml-[20px] text-[20px]'>SAFETY GEARS</span>
            </div>
            <div className='flex items-center m-[10px] md:ml-[70px]'>
              <img src={Images?.supportWear} alt="" className='w-[50px] lg:w-auto' />
              <span className='text-black text-lg ml-[20px] text-[20px]'>MOISTURE WICKING SPORTS WEAR</span>
            </div>
            <div className='flex items-center m-[10px] md:ml-[110px]'>
              <img src={Images?.finisherMedal} alt="" className='w-[50px] lg:w-auto' />
              <span className='text-black text-lg ml-[20px] text-[20px]'>FINISHER’s MEDAL & CERTIFICATE</span>
            </div>
          </div>
        </div>
      </div> */}
      <div className={`md:py-20 ${screenSize <= 425 ? "mt-7" : ""}`}>
        <img
          src={screenSize <= 425 ? Images?.mobileHeroKit : Images?.heroKit}
          alt="Shish Superhero Kit "
        />
      </div>
    </>
  );
}

export default SuperHero;
