import React from "react";
import { Images } from "../../../constants/images";

const missionCards = [
  {
    id: 1,
    img: Images.mission1,
  },
  {
    id: 2,
    img: Images.mission2,
  },
  {
    id: 3,
    img: Images.mission3,
  },
  {
    id: 4,
    img: Images.mission4,
  },
  {
    id: 5,
    img: Images.mission5,
  },
  {
    id: 6,
    img: Images.mission6,
  },
];

const overviewCards = [
  {
    id: 1,
    img: Images.overview1,
    text: "SURAT TO SARANGPUR",
  },
  {
    id: 2,
    img: Images.overview2,
    text: "333 KMS",
  },
  {
    id: 3,
    img: Images.overview3,
    text: "January 31st, 2024 to February 2nd, 2024",
  },
];

const distanceCard = [
  {
    id: 1,
    img: Images.Days,
  },
  {
    id: 2,
    img: Images.kms,
  },
  {
    id: 3,
    img: Images.superHero,
  },
  // {
  //   id: 4,
  //   img: Images.pratigya
  // },
];

const Adventure = ({ openModal }) => {
  return (
    // <div>
    //   <h1 className="md:mb-10 lg:mb-[70px] text-[24px] md:text-[60px] lg:text-[100px] text-[#007F3D] text-center font-extrabold gradient_txt">
    //     SURAT TO SARANGPUR
    //   </h1>
    //   <div className="grid grid-cols-1 md:grid-cols-2 ">
    //     <img alt="about-section" src={Images.about_shish} className="" />
    //     <div className="grid grid-cols-2 ">
    //       {missionCards.map((el) => (
    //         <img
    //           key={`mission_card_${el.id}`}
    //           alt={`mission_card_${el.id}`}
    //           src={el.img}
    //         />
    //       ))}
    //     </div>
    //   </div>
    //   <div className="grid grid-cols-1 md:grid-cols-2">
    //     <img
    //       alt="about-section"
    //       src={Images.mission_video}
    //       className="h-full"
    //     />
    //     <div className="h-full px-[20px] 2xl:px-[100px] py-5 text-white bg-[#1A1917] flex flex-col justify-center">
    //       <div className="flex items-center">
    //         <img
    //           src={Images?.line}
    //           alt=""
    //           className="w-[110px] h-[3px] mr-[20px]"
    //         />
    //         <h3 className="text-[45px] font-normal">EVENT</h3>
    //       </div>
    //       <h4 className="2xl:ml-10 text-[45px] font-extrabold">OVERVIEW</h4>
    //       <p className="2xl:ml-[100px] mb-10">
    //         Rev up your superhero spirit for a mission of sustainability and
    //         health! The SHISH Cyclothon 2024 is calling on all superheroes to
    //         join the cause. As we journey from Surat to Sarangpur, discovering
    //         scenic wonders, we invite you to register and become part of this
    //         impactful adventure.
    //       </p>
    //       <div className="lg:ml-10">
    //         <div className="mb-5 lg:mb-10 grid grid-cols-1 gap-y-2.5">
    //           {overviewCards.map((el) => (
    //             <div className="flex items-center gap-x-5">
    //               <img
    //                 alt=""
    //                 src={el.img}
    //                 className="w-[55px] h-[52px] object-contain"
    //               />
    //               <p>{el.text}</p>
    //             </div>
    //           ))}
    //         </div>
    //         <button
    //           className="w-fit py-3 px-6 text-[#1A1917] font-semibold rounded-[5px] bg-white"
    //           onClick={() => openModal()}
    //         >
    //           REGISTER NOW
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="py-[38px]  md:px-[80px] bg-[#1A1917] flex flex-col sm:flex-row items-center">
      <div className="w-full sm:w-[30%] md:w-[50%] text-center sm:text-left mr-0 sm:mr-[40px]">
        <h2 className="text-[#fff] text-[30px] md:text-[46px] font-bold">
          You Pedal, We Donate!
        </h2>
      </div>
      <div className="w-full sm:w-[60%] md:w-[50%] flex flex-col lg:flex-row items-center ml-0 2xl:ml-[180px]">
        {/* <h3 className="text-[#00D4B7] text-[48px] font-semibold leading-[110%] tracking-[1.44px] mr-[30px]">Surat To <br className="hidden sm:block" /> Sarangpur</h3> */}
        <h3 className="text-[#00D4B7] text-[22px] sm:text-[48px] text-center sm:text-left font-semibold leading-[110%] tracking-[1.44px] mb-[20px] lg:mb-0 mr-0 sm:mr-6">
          Surat To
          <span className="hidden sm:flex">Sarangpur</span>
          <br className="hidden sm:hidden" />
          <span className="sm:hidden"> Sarangpur</span>
        </h3>
        <div className="flex flex-row mt-[20px] sm:mt-0">
          {distanceCard.map((el, index) => (
            <img
              key={`mission_card_${el.id}`}
              alt={`mission_card_${el.id}`}
              src={el.img}
              className={`h-[90px] sm:h-[100px] w-[90px] sm:w-[80px] xl:w-[100px] ${
                index == 2 ? "mr-[0px]" : "mr-[10px]"
              } sm:mr-[40px] mb-[10px] sm:mb-0`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Adventure;
