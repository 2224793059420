import React, { useEffect } from "react";
import "./index.css";
function TermsOfUse() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  let policy = `<!DOCTYPE html>
  <html>
    <head>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <title></title>
      <meta name="generator" content="LibreOffice 7.4.3.2 (Linux)" />
      <meta name="author" content="Kinjal Joshi" />
      <meta name="created" content="2023-12-27T04:45:51.176437400" />
      <meta name="changedby" content="Kinjal Joshi" />
      <meta name="changed" content="2023-12-27T13:06:54.474984300" />
      <meta name="AppVersion" content="16.0000" />
      <style type="text/css">
      * { 
        line-height:30px
      }
        @page {
          size: 8.5in 11in;
          margin: 1in
        }
  
        p {
          line-height: 115%;
          text-align: left;
          orphans: 2;
          widows: 2;
          margin-bottom: 0.1in;
          direction: ltr;
          background: transparent
        }
  
        a:link {
          color: #0563c1;
          text-decoration: underline
        }
  
        .numberText {
          list-style-type: decimal;
          margin-left: 35px;
          font-family: "Times New Roman";
          font-size: 16px;
          font-weight: normal;
          background-color: #ffffff;
        }
      </style>
    </head>
    <body lang="en-US" link="#0563c1" vlink="#800000" dir="ltr">
    <p align="left" style="line-height: 108%; margin-bottom: 0.11in">
    <font face="Calibri, serif"
      ><font size="4" style="font-size: 14pt"
        ><span lang="en-US"><b>Terms & Conditions</b></span></font
      ></font
    >
  </p>
      <ol class="number">
        <li>
          <p style=" line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="numberText font-weight: normal">The Event is organized by Shish Cyclothon, with its registered office at Plot C, Suryapur mill compound beside Mahesana co.op. Bank, Varachha, Surat, GJ-395006, India.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants will be deemed to have read and accepted these terms and agree to be bound by them. If the participant does not agree with any of these terms, they should not enter the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">The participant's failure to accept or comply with the provisions of these terms &amp; conditions will disqualify them from the Event. The decision of Shish Cyclothon for all matters relating to participant participation shall be final and binding in all respects.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">These terms and conditions shall be irrevocably binding on each participant voluntarily participating in the Event. Participants hereby agree and understand that Shish Cyclothon will have access to and collect information and data shared by the participant.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Each cyclist will be assigned a number for easy identification.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">All cyclists must participate in daily training sessions within their allocated groups.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants and volunteers must strictly adhere to room allocations, cycling orders, and other arrangements set by the management.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Cyclists should stay in their designated groups and follow the guidance of the group leader.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <a name="_Int_ElntOY3t"></a>
            <a name="_Int_9oNNyHLo"></a>
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">A bus will be available exclusively for participants. Only 10% of participants can take rest in the bus at a time. A token system will be followed, wherein if the 34th participant wants to rest, the 1st participant will have to leave the bus.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">The convoy will have one car at the front and one at the end, and cyclists must not cross the convoy.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Cyclists should stay on the leftmost lane and refrain from crossing borders while cycling.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">All cycles should form a single line and avoid driving side by side.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">No overtaking the front cycle or lingering behind the last vehicle in the convoy.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Talking on mobile phones is strictly prohibited while cycling.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Carrying or consuming alcohol, drugs, or intoxicating materials is strictly prohibited.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">No substitutes or proxies are allowed for cycling without prior permission.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Quitting is not an option, and no midway returns are allowed.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Only one cabin-sized bag/backpack is allowed, with proper tagging indicating the cyclist's name and number or the volunteer's name.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">During cycling, participants must wear the sportswear provided by the company for safe cycling.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0.11in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">During the Havan in Sarangpur, participants and volunteers must adhere to the decided dress code.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants need to choose the Event category carefully while filling out the application form. Confirmed registrations are non-refundable, non-transferable, and cannot be modified at later stages.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants must provide Shish Cyclothon with a secure and operational email ID/mobile number for communication. Along with personal contact details, participants should provide a mobile number of any immediate family member for emergency contact.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants are aware that long-distance cycling is an extreme sport and can be injurious to the body and health. Participants take full responsibility for participating in the Event and do not hold Shish Cyclothon or any of its members, affiliates, or entities responsible for any injury or accident.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants shall consult their physician and undergo a complete medical examination to assess their suitability to participate in the event. Participants agree that they are physically and mentally capable of riding the chosen distance.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants assume all risks associated with participating in the Event, including falls, contact with other participants, weather conditions, traffic, and the condition of the road.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <a name="_Int_Y0UdRSIv"></a>
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants agree that Shish Cyclothon shall not be liable for any loss, damage, illness, injury, or incident that might occur as a result of participation in the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants agree to abide by the instructions provided by Shish Cyclothon for the safety of participants and the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants also agree to stop cycling if instructed by the Race director, medical staff, or aid station volunteers.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants confirm that their name and media recordings taken during participation may be used for publicity and other promotional activities.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants acknowledge and agree that their personal information can be stored and used by Shish Cyclothon or any other company in connection with the organization, promotion, and administration of the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants confirm that, in the event of adverse weather conditions, major incidents, or threats, Shish Cyclothon reserves the right to stop/cancel/postpone the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Confirmed registrations and merchandise orders are non-refundable, non-transferable, and cannot be modified. Shish Cyclothon reserves the right to reject any application without providing reasons.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <a name="_Int_KBkmZlCq"></a>
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Cyclists are recommended to stay off the course beyond the cut-off time for safety and traffic regulations.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">If a participant cannot turn up on race day for any reason, no refund of any form will be given.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">The Event Application Form must be completed by a person aged 18 years or over. Evidence of age needs to be submitted with Shish Cyclothon.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants understand and agree that they may be contacted by the Sponsors and Promoters of the Event for marketing and promotion offers and grant explicit permission to receive communications on their mobile number &amp; email address.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <a name="_Int_NtmCPqHQ"></a>
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants agree to receive pre and post-event communications from Shish Cyclothon. Emails can be unsubscribed, but doing so will prevent all future Event updates being sent.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Participants hereby indemnify and hold harmless Shish Cyclothon and its affiliates, their respective trustees, directors, employees, officers, sub-licensees, assignees, representatives, and partners from any claim, loss, damages, liability, or cost arising out of any breach of warranty, representation, or undertaking made by the participant.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Shish Cyclothon may undertake publicity activities relating to competitions.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Shish Cyclothon's decision in respect of all matters to do with the Event shall be final, and no correspondence will be entered into.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Shish Cyclothon reserves the right to cancel, modify, extend, or withdraw the Event prematurely at its sole and absolute discretion and without notice.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">To the extent permitted by law, Shish Cyclothon and its agents and representatives hereby expressly exclude any liability for any direct, indirect, or consequential loss, damage, injury, or disappointment suffered or incurred by any participant, winner, or any third party in connection with the Event.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">Shish Cyclothon is not responsible and shall not be held liable for any physical injury, death, mental and/or emotional trauma caused to the participant in relation to the Event and/or any matters arising out of the terms and conditions.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
        <li>
          <p style="line-height: 108%; margin-bottom: 0in">
            <span style="font-variant: normal">
              <font color="#000000">
                <font face="Calibri, serif">
                  <font size="3" style="font-size: 12pt">
                    <span lang="en-US">
                      <span style="font-style: normal">
                        <span style="font-weight: normal">No refunds or cancellations will be provided once registered for Shish Cyclothon.</span>
                      </span>
                    </span>
                  </font>
                </font>
              </font>
            </span>
          </p>
      </ol>
      <p style="line-height: 108%; margin-bottom: 0.11in">
        <br />
        <br />
      </p>
    </body>
  </html>`;
  return (
    <div
      className="m-10 leading-10"
      dangerouslySetInnerHTML={{ __html: policy }}
    />
  );
}

export default TermsOfUse;
