// Layout.js

import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
