import React from "react";
import { Images } from "../../../constants/images";

const OurImpact = () => {
  return (
    <div className="pr-[20px] lg:pr-[80px] py-[20px] lg:py-[96px] flex flex-col lg:flex-row items-center relative">
      <div className="w-full lg:w-[50%] mt-[20px] lg:mt-0">
        <img
          src={Images.impact}
          className="hidden lg:block h-auto lg:h-[410px]"
          alt="Hands in"
        />
      </div>

      <div className="w-full lg:w-[50%]">
        <div className="h-auto">
          <div className="flex items-center lg:mb-[10px] justify-start -ml-[40px]">
            <img src={Images?.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-[#1A1917] text-3xl font-normal">RECEIPT</span>
          </div>
        </div>
        <span className="ml-[15px] mx-0 lg:mx-[15px] font-extrabold text-3xl lg:text-[40px] gradient_txt">
          OF IMPACT
        </span>
        <img
          src={Images.impact}
          className="block lg:hidden h-auto lg:h-[410px]"
          alt="about cyclothon"
        />
        <div className="mx-[15px] text-left">
          <div className="text-[#3F3F3F] sm:text-[22px] text-[16px] font-normal mt-[20px]">
            <span>
              After completing your Cyclothon, you'll receive a donation receipt
              confirming your contribution to a noble cause. For every pedal you
              make, we will donate Rs. 10 to support "Fostering Inclusion for
              Vulnerable Populations." Join us in making a difference.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
