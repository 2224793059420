import logo from "../assets/images/logo.png";
import SuperHeroKitImage from "../assets/images/superHeroKit.png";
import home_banner from "../assets/images/home_banner.png";
import about_shish from "../assets/images/about_shish.png";
import mission1 from "../assets/images/mission1.png";
import mission2 from "../assets/images/mission2.png";
import mission3 from "../assets/images/mission3.png";
import mission4 from "../assets/images/mission4.png";
import mission5 from "../assets/images/mission5.png";
import mission6 from "../assets/images/mission6.png";
import mission_video from "../assets/images/mission_video.png";
import overview1 from "../assets/images/overview1.png";
import overview2 from "../assets/images/overview2.png";
import overview3 from "../assets/images/overview3.png";
import route_map from "../assets/images/route_map.png";
import day1 from "../assets/images/day1.png";
import day2 from "../assets/images/day2.png";
import day3 from "../assets/images/day3.png";
import day4 from "../assets/images/day4.png";
import bicycle from "../assets/images/Ellipse 231.png";
import safetyGear from "../assets/images/Ellipse 231 (1).png";
import supportWear from "../assets/images/Ellipse 231 (2).png";
import finisherMedal from "../assets/images/Ellipse 231 (3).png";
import supportSectionBgImage from "../assets/images/31.png";
import waterBottel from "../assets/images/Bottle.png";
import refreshment from "../assets/images/Group 1171276307.png";
import medical from "../assets/images/medical kit.png";
import massage from "../assets/images/massage 1.png";
import collaboration from "../assets/images/collaboration (1) 1.png";
import hotel from "../assets/images/_Group_.png";
import dinner from "../assets/images/Group.png";
import liveMusic from "../assets/images/image 5.png";
import speech from "../assets/images/image 6.png";
import eventActivity from "../assets/images/image 6 (1).png";
import professionalPhotoVideo from "../assets/images/image 5 (1).png";
import sarangpurTemple from "../assets/images/Daco_4761876 1.png";
import growderLogo from "../assets/images/Growder_TM 2.png";
import pdsLogo from "../assets/images/image 248.png";
import shishLogo from "../assets/images/image 71.png";
import line from "../assets/images/Line 4.png";
import shishCyclothonLogo from "../assets/images/Group 892.png";
import heroKit from "../assets/images/Group 1060.png";
import modelBanner from "../assets/images/modelBanner.png";
import supporter1 from "../assets/images/supporter1.png";
import supporter2 from "../assets/images/supporter2.png";
import supporter3 from "../assets/images/supporter3.png";
import supporter4 from "../assets/images/supporter4.png";
import supporter5 from "../assets/images/supporter5.png";
import supporter6 from "../assets/images/supporter6.png";
import supporter7 from "../assets/images/supporter7.png";
import supporter8 from "../assets/images/supporter8.png";
import packagingDepotLogo from "../assets/images/packagingDepotLogo.png";
import livingBg from "../assets/images/livingBg.png";
import landingPageHeroSectionBGImage from "../assets/images/Home pg banner.png";
import Days from "../assets/images/Group 1171276181 (1).png";
import kms from "../assets/images/Group 1171276182 (1).png";
import superHero from "../assets/images/Group 1171276183.png";
import pratigya from "../assets/images/Group 1171276184.png";
import aboutUsBanner from "../assets/images/Rectangle 28 (1).png";
import visionBanner from "../assets/images/cyclist-resting-silhouette-sunset-active-outdoor-sport-concept 1 (1).png";
import fitIndiaImage from "../assets/images/fit india 1.png";
import coupleCyclingImage from "../assets/images/asian-cyclist-couple-riding-together-exercise-around-lake-morning 1.png";
import shishLogo2 from "../assets/images/shish logo 2.png";
import coupleBiker from "../assets/images/biker-couple-with-mountain-bike-pointing-distance 1 (1).png";
import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook (1).png";
import twitter from "../assets/images/twitter 1 (1).png";
import registerHeroSectionBanner from "../assets/images/slim-beautiful-woman-silhouette-doing-sports-morning-park-doing-yoga 1 (1).png";
import mapBg from "../assets/images/Rectangle 27.png";
import fullMapImg from "../assets/images/Rectangle 28.png";
import day1MapImg from "../assets/images/1.png";
import day2MapImg from "../assets/images/2.png";
import day3MapImg from "../assets/images/3.png";
import day4MapImg from "../assets/images/4.png";
import cyclingFitness from "../assets/images/cycling-fitness-senior-man-drinking-water-park-hydration-during-training-exercise-nature-bottle-thirsty-elderly-athlete-with-electrolytes-break-sports-liquid-forest-workout.png";
import firstAidKit from "../assets/images/first-aid-kit-white-table-full-set-emergency-medicine-medication-giving-first-aid-sick-injured-person-white-background.png";
import supportCrew from "../assets/images/eco-concept-with-group-volunteers.png";
import accommodation from "../assets/images/pillow-bed.png";
import meal from "../assets/images/flat-lay-composition-tasty-batch-food-cooked.png";
import winnerMedal from "../assets/images/winnerImage.png";
import beneficiaries1 from "../assets/images/beneficiaries1.png";
import beneficiaries2 from "../assets/images/beneficiaries2.png";
import impact from "../assets/images/impact.png";
import modiSaab from "../assets/images/modisaab.png";
import quote from "../assets/images/Quote.png";
import suratCityPolice from "../assets/images/surat police.png";
import gujaratPolice from "../assets/images/Gujarat_Police_Logo.png";
import cycleGreenEnergy from "../assets/images/cycling green energy 1.png";
import quote1 from "../assets/images/Quote (1).png";
import delevirables from "../assets/images/delevirables.png";
import tshirt from "../assets/images/tshirt.png";
import medical2 from "../assets/images/medical.png";
import certificate from "../assets/images/certificate.png";
import receipt from "../assets/images/receipt.png";
import aboutUsBg from "../assets/images/aboutUsbg.png";
import aboutus1 from "../assets/images/aboutus1.png";
import aboutus2 from "../assets/images/aboutus2.png";
import ourCause from "../assets/images/ourCause.png";
import pledge from "../assets/images/pledge.png";
import leftLine from "../assets/images/Rectangle 225.png";
import rightLine from "../assets/images/Rectangle 226.png";
import pedal from "../assets/images/sustainable-development-goals-still-life (1) 1 (1).png";
import spirit from "../assets/images/sustainable-development-goals-still-life (1) 1 (2).png";
import fitIndia from "../assets/images/sustainable-development-goals-still-life (1) 1.png";
import cause1 from "../assets/images/cause1.png";
import cause2 from "../assets/images/cause2.png";
import cause3 from "../assets/images/cause3.png";
import cause4 from "../assets/images/cause4.png";
import cause5 from "../assets/images/cause5.png";
import cross from "../assets/images/cross.png";
import liveMusic1 from "../assets/images/Vector.png";
import eveningActivity from "../assets/images/Vector (1).png";
import inspirationSpeech from "../assets/images/Group 1171276288.png";
import sightSeeing from "../assets/images/sightSeeing.png";
import quoteMobile from "../assets/images/quoteMobile.png";
import livingBgMobile from "../assets/images/livingBgMobile.png";
import leftTop from "../assets/images/left_top.png";
import roundRight from "../assets/images/round_right.png";
import sivatirath from "../assets/images/Cyc.png";
import smc_logo from "../assets/images/smc_logo.webp";
import mobileheroKit from "../assets/images/mobileheroKit.png";
import mobileQuote1 from "../assets/images/mobileQuote1.png";
import greensqr from "../assets/images/greensqr.png";
import greencycle from "../assets/images/greencycle.png";
import mediaBanner from "../assets/images/mediaBanner.png";
import mediaTextBgImage from "../assets/images/mediaTextBgImage.png";
import triangle from "../assets/images/triangle.png";
import youTubeLogo from "../assets/images/youtube-svgrepo-com 1.png";

export const Images = {
  logo,
  SuperHeroKitImage: SuperHeroKitImage,
  home_banner,
  about_shish,
  mission1,
  mission2,
  mission3,
  mission4,
  mission5,
  mission6,
  mission_video,
  overview1,
  overview2,
  overview3,
  route_map,
  day1,
  day2,
  day3,
  day4,
  line: line,
  bicycle: bicycle,
  safetyGear: safetyGear,
  supportWear: supportWear,
  finisherMedal: finisherMedal,
  supportSectionBgImage: supportSectionBgImage,
  waterBottel: waterBottel,
  refreshment: refreshment,
  medical: medical,
  massage: massage,
  collaboration: collaboration,
  hotel: hotel,
  dinner: dinner,
  liveMusic: liveMusic,
  speech: speech,
  eventActivity: eventActivity,
  professionalPhotoVideo: professionalPhotoVideo,
  sarangpurTemple: sarangpurTemple,
  growderLogo: growderLogo,
  pdsLogo: pdsLogo,
  shishLogo: shishLogo,
  shishCyclothonLogo: shishCyclothonLogo,
  heroKit: heroKit,
  modelBanner,
  supporter1,
  supporter2,
  supporter3,
  supporter4,
  supporter5,
  supporter6,
  supporter7,
  supporter8,
  packagingDepotLogo,
  livingBg,
  landingPageHeroSectionBGImage,
  Days,
  kms,
  superHero,
  pratigya,
  aboutUsBanner,
  visionBanner,
  fitIndiaImage,
  coupleCyclingImage,
  shishLogo2,
  coupleBiker,
  instagram,
  facebook,
  twitter,
  registerHeroSectionBanner,
  mapBg,
  fullMapImg,
  day1MapImg,
  day2MapImg,
  day3MapImg,
  day4MapImg,
  cyclingFitness,
  firstAidKit,
  supportCrew,
  accommodation,
  meal,
  winnerMedal,
  beneficiaries1,
  beneficiaries2,
  impact,
  modiSaab,
  quote,
  suratCityPolice,
  gujaratPolice,
  cycleGreenEnergy,
  quote1,
  delevirables,
  tshirt,
  medical2,
  certificate,
  aboutUsBg,
  aboutus1,
  aboutus2,
  ourCause,
  pledge,
  leftLine,
  rightLine,
  pedal,
  spirit,
  fitIndia,
  cause1,
  cause2,
  cause3,
  cause4,
  cause5,
  cross,
  liveMusic1,
  eveningActivity,
  inspirationSpeech,
  sightSeeing,
  quoteMobile,
  livingBgMobile,
  leftTop,
  roundRight,
  SMC_Logo: smc_logo,
  Savartirath: sivatirath,
  mobileHeroKit: mobileheroKit,
  mobileQuote1,
  greensqr,
  greencycle,
  mediaBanner,
  mediaTextBgImage,
  triangle,
  youTubeLogo,
};
