import React from "react";
import { Images } from "../../constants/images";

function VisionSection() {
  return (
    <div className="pr-[20px] lg:pr-[80px] py-[20px] lg:py-[96px] flex flex-col lg:flex-row items-center relative">
      <div className="w-full lg:w-[65%] mt-[20px] lg:mt-0">
        <img
          src={Images.visionBanner}
          className="h-auto lg:h-[410px] object-contain"
          alt="Cyclist and Sunset view"
        />
      </div>

      <div className="w-full lg:w-[35%]">
        <div className="h-auto">
          <div className="flex items-center mb-[10px] justify-start lg:justify-end mr-[80px]">
            <img src={Images?.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-[#1A1917] text-3xl font-normal">OUR</span>
          </div>
        </div>

        <div className="mx-[15px] text-left lg:text-right">
          <span className=" font-extrabold text-3xl lg:text-[40px] gradient_txt">
            VISION
          </span>
          <div className="text-[#3F3F3F] text-[22px] font-normal mt-[20px]">
            <span>
              Our cycling event strives to create a vibrant community of
              enthusiasts and we aim to welcome 1,00,000 SuperHeroes in the next
              5 years into our spirited gatherings, fostering a sustainable,
              healthier and greener tomorrow.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionSection;
