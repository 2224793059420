import { useEffect, useState } from "react";
import { Images } from "../../../constants/images";

const delevirableCard = [
  {
    img: Images.tshirt,
    text: "T-shirt",
  },
  {
    img: Images.medical2,
    text: "Medical Support",
  },
  {
    img: Images.certificate,
    text: "Cyclothon Completion Certificate",
  },
  {
    img: Images.certificate,
    text: "Donation Receipt",
  },
];

const cyclistCard = [
  {
    title: "Pedal with Purpose:",
    text: "Your participation contributes to a noble cause, promoting sustainability and health.",
    image: Images.fitIndia,
  },
  {
    title: "Community Spirit:",
    text: "Join a unified community of cycling enthusiasts sharing a passion for a greener planet.",
    image: Images.pedal,
  },
  {
    title: "Fit India Support:",
    text: "Embrace the Fit India spirit and embark on a journey of health, wellness, and collective fitness excellence.",
    image: Images.spirit,
  },
];

const Delevirables = () => {
  const [isMobile, setIsMobile] = useState(window.screen.width);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="grid lg:grid-cols-2 mt-20">
        <img alt="" src={Images.delevirables} />
        <div className="grid grid-cols-4 gap-x-5 justify-items-center">
          {delevirableCard.map((el) => (
            <div className="flex flex-col items-center">
              <img
                alt=""
                src={el.img}
                className={`${isMobile <= 280 ? "w-[55px]" : ""} ${
                  isMobile <= 320 ? "w-[50px]" : "w-[120px]"
                } `}
              />
              <p
                className={`flex-wrap text-center ${
                  isMobile <= 425 ? "text-[12px]" : ""
                }`}
              >
                {el.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[16px] md:mt-[40px] lg:mt-[80px]">
        <div className="flex flex-col md:flex-row justify-between sm:items-center">
          <img
            src={Images.leftLine}
            alt=""
            className="h-[26px] lg:h-[42px] 2xl:h-[50px]"
          />
          <span className="uppercase sm:text-[26px] lg:text-[42px] 2xl:text-[50px] flex justify-center">
            be a cyclist for change
          </span>
          <img
            src={Images.rightLine}
            alt=""
            className="h-[26px] lg:h-[42px] 2xl:h-[50px]"
          />
        </div>
        <div className="w-[95%] lg:w-[80%] flex flex-wrap items-center justify-center sm:justify-between m-auto mt-[20px] sm:mt-[60px]">
          {cyclistCard.map((card) => {
            return (
              <div className="relative w-[47%] md:w-[30%] mb-[20px] mr-2 md:mb-0">
                <img src={card.image} alt="pedal" className="" />
                <div
                  className={`absolute left-0 right-0 ${
                    isMobile < 640 && isMobile > 320 ? "top-[6vw]" : "top-0"
                  } sm:top-[4vw] xl:top-[6vw] flex flex-col justify-center text-center w-full 2xl:w-[75%] m-auto`}
                >
                  <h4 className="text-[14px] md:text-[22px] 2xl:text-[30px] text-white font-semibold">
                    {card.title}
                  </h4>
                  <span className="text-[12px] sm:text-[16px] xl:text-[22px] text-white mt-[10px] sm:mt-[32px]">
                    {card.text}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Delevirables;
