import React, { useContext, useEffect, useState } from "react";
import SuperHeroDayMapSection from "../allDayMap";
import SupportAndEvent from "../supportAndEvent";
import SuperheroRegisteration from "../../../components/forms/superheroRegisteration";
import Delevirables from "../delevirables";
import OurImpact from "../ourImpact";
import OurBeneficiaries from "../ourBeneficiaries";
import EventDetail from "../eventDetails";

function RegisterForm() {
  const [activeTab, setActiveTab] = useState(2);
  const [isStudent, setIsStudent] = useState(false);
  const initFormObject = {
    name: {
      value: "",
      maxLength: 90,
      regex: /.{2,}/,
      isValid: true,
      errorMessage: "Please enter valid name !",
    },
    startingVenue: {
      value: "",
      regex: /.{2,}/,
      isValid: true,
      errorMessage: "Please select any venue",
    },
    gender: {
      value: "male",
      regex: "",
      isValid: true,
      errorMessage: "",
    },
    age: {
      value: "",
      inputType: "number",
      maxLength: 3,
      regex: /^(1[8-9]|[2-9]\d+)$/,
      isValid: true,
      errorMessage: "Age must be greater than 18 or equal to 18",
    },
    bloodGroup: {
      value: "",
      maxLength: 12,
      regex: /.{2,}/,
      isValid: true,
      errorMessage: "Please select your blood group",
    },
    mobileNo: {
      value: "",
      maxLength: 10,
      regex: /^\d{10}$/,
      isValid: true,
      errorMessage: "Please enter valid mobile number !",
    },
    emergencyNumber: {
      value: "",
      maxLength: 10,
      regex: /^\d{10}$/,
      isValid: true,
      errorMessage: "Please enter valid mobile number !",
    },
    email: {
      value: "",
      maxLength: 60,
      regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      isValid: true,
      errorMessage: "Please enter valid email !",
    },
    address: {
      value: "",
      maxLength: 255,
      regex: /.{2,}/,
      isValid: true,
      errorMessage: "Please enter valid address !",
    },
    pinCode: {
      value: "",
      inputType: "number",
      maxLength: 6,
      regex: /^[1-9][0-9]{5}$/,
      isValid: true,
      errorMessage: "Please enter valid pincode !",
    },
    tshirtSize: {
      value: "",
      regex: /.{1,}/,
      isValid: true,
      errorMessage: "Select Tshirt size!",
    },
    photoUrl: {
      value: "",
      regex: /.{1,}/,
      isValid: true,
      errorMessage: "Please upload your image",
    },
    governmentId: {
      value: "",
      regex: /.{1,}/,
      isValid: true,
      errorMessage: "Please upload  governmentId",
    },
    promoCode: {
      value: "",
      maxLength: 15,
      isValid: true,
      errorMessage: "Please enter valid promocode !",
    },
  };
  const [form, setForm] = useState(initFormObject);

  useEffect(() => {
    setForm(initFormObject);
    if (activeTab === 1) setIsStudent(false)
  }, [activeTab]);
  return (
    <div className="mt-[20px] md:mt-[80px]">
      <div className="px-[16px] sm:px-[40px] flex flex-row  justify-between text-center">
        <span
          className={`md:text-[26px] text-[12px] mr-1 sm:mr-0 lg:text-[40px] font-bold uppercase cursor-pointer ${activeTab === 1 ? "text-[#007B97]" : "text-black"
            }`}
          onClick={() => setActiveTab(1)}
        >
          Registration for SuperHeroes{" "}
        </span>
        <span
          className={`md:text-[26px] text-[12px] lg:text-[40px] font-bold uppercase cursor-pointer ${activeTab === 2 ? "text-[#007B97]" : "text-black"
            }`}
          onClick={() => setActiveTab(2)}
        >
          Registration for CauseHeroes
        </span>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1920"
          height="17"
          viewBox="0 0 1920 17"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1920 17H0V0H1920V17Z"
            fill="url(#paint0_linear_865_660)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_865_660"
              x1="93.5"
              y1="112.588"
              x2="1884.5"
              y2="112.703"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#007A98" />
              <stop offset="1" stop-color="#00A593" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <SuperheroRegisteration
        activeTab={activeTab}
        form={form}
        setForm={setForm}
        initFormObject={initFormObject}
        isStudent={isStudent}
        setIsStudent={setIsStudent}
      />

      {activeTab === 1 && (
        <>
          <SuperHeroDayMapSection />
          <SupportAndEvent />
        </>
      )}
      {activeTab === 2 && (
        <>
          <EventDetail />
          <Delevirables />
          <OurImpact />
          <OurBeneficiaries />
        </>
      )}
    </div>
  );
}

export default RegisterForm;
