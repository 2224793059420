import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useRazorpay from "react-razorpay";
import { Checkbox, Modal, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import Input from "../input";
import Select from "../select";
import InputTitle from "../inputTitle";
import ImageUploadInput from "../uploadImg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Images } from "../../constants/images";
import PassDownloadModal from "./PassDownloadModal";
const startingLocation = [
  { name: "SMC Party Plot, Umra, Surat" },
  // { name: "BAPS Mandir, Bharuch" },
  { name: "SRP Ground, Sussen Circle, Vadodara" },
];

const sizes = [
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "XXL" },
];

const bloodGroup = [
  { name: "A+" },
  { name: "A-" },
  { name: "B+" },
  { name: "B-" },
  { name: "AB+" },
  { name: "AB-" },
  { name: "O+" },
  { name: "O-" },
];
let tempImageURL;
const SuperheroRegisteration = ({
  activeTab,
  form,
  setForm,
  initFormObject,
  isStudent,
  setIsStudent,
}) => {
  const [openCertificate, setOpenCertificate] = useState(false);
  const [isOpenPassModal, setIsOpenPassModal] = useState(false);
  const [promoCodeDetail, setPromoCodeDetail] = useState({
    promoCodeResponce: {},
    isPromocodeValid: false,
  });
  // const [isStudent, setIsStudent] = useState(false);
  const [Razorpay] = useRazorpay();
  const navigation = useNavigate();
  let imageURL;
  const imageRef = useRef(null);
  // const [form, setForm] = useState(initFormObject);
  console.log("form111", form);
  const [isTermChecked, setIsTermChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const canvasRef = useRef(null);

  const handleInputChange = ({ type, value }) => {
    setForm({
      ...form,
      [type]: {
        ...form[type],
        isValid: true,
        value,
      },
    });
  };

  const handleUploadImage = async ({ event, type, isError, msg }) => {
    if (isError) {
      setForm({
        ...form,
        [type]: {
          ...form[type],
          isValid: false,
          errorMessage: msg || "",
        },
      });
    } else {
      const file = event?.target?.files?.[0];
      tempImageURL = event?.target?.files;
      // create a new FormData object and append the file to it
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const imgUrl = await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/user/UploadImageCyclothon`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // if (type == "photoUrl") {
        //   loadImage(imgUrl?.data?.data?.url);
        // }
        setForm({
          ...form,
          [type]: {
            ...form[type],
            isValid: true,
            value: imgUrl?.data?.data?.url,
          },
        });
      }
    }
  };

  const handlePayment = async (payementidCallBack) => {
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: Number(process.env.REACT_APP_AMOUNT) * 100,
        currency: "INR",
        name: "Shish Cyclothon",
        description: ``,
        image: "",
        handler: function (response) {
          setIsLoading(true);
          payementidCallBack(response);
        },

        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        notification.open({
          description: "Something went wrong!",
          placement: "bottomRight",
          type: "error",
        });
      });

      rzp1.open();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const callApi = async ({ body, image }) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/user/saveUser`,
        body
      );

      setIsTermChecked(false);
      setIsStudent(false);
      setForm(initFormObject);
      if (image) {
        {
          const response = await fetch(image);
          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = `Entry_pass_${form.name.value}.jpg`;
          link.click();
          URL.revokeObjectURL(blobUrl);
        }
      }

      setForm(initFormObject);
      notification.open({
        description:
          "We have received your request, and we will contact you soon.",
        placement: "bottomRight",
        type: "success",
      });
      setIsLoading(false);
    } catch (err) {
      notification.open({
        description: `${err?.response?.data?.data?.message}`,
        placement: "bottomRight",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const isMobileNoExist = async (mobileNo, email) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/user/mobileNumberCheck?mobileNumber=${mobileNo}&email=${email}`
      );
      return false;
    } catch (err) {
      notification.open({
        description: err.response.data.data?.message,
        placement: "bottomRight",
        type: "error",
      });
      return true;
    }
  };

  const promoCodeCheckFn = (code, callback) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}admin/user/promoCodeCheck/${code}`
        )
        .then((res) => {
          callback(res?.data, true);
        })
        .catch((err) => {
          callback({}, false);
        });
    });
  };

  const payForSuperHero = (type) => {
    let body = {
      id: 0,
      address: form.address.value,
      age: form.age.value,
      area: "",
      bloodGroup: form.bloodGroup.value,
      city: "",
      email: form.email.value,
      emergencyNumber: form.emergencyNumber.value,
      gender: form.gender.value,
      governmentId: form.governmentId.value,
      mobileNo: form.mobileNo.value,
      name: form.name.value,
      paymentId: "",
      photoUrl: form.photoUrl.value,
      pinCode: form.pinCode.value,
      startVenue: form.startingVenue.value,
      state: "",
      termsAndConditions: "True",
      travellingDistance: 0,
      tshirtSize: form.tshirtSize.value,
      userType: type === "pay" ? "PARTICIPANT" : "SUPERHERO",
    };

    callApi({ body });
  };
  const payForCauseHero = async (type, status) => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setOpenCertificate(true);
    let userImage = new Image();
    userImage.src = form?.photoUrl?.value;
    userImage.onload = () => {
      console.log("image loaded");
      let leftTop = new Image();
      leftTop.src = Images.leftTop;
      leftTop.onload = () => {
        console.log("callledddddddd left top");
        let roundRight = new Image();
        roundRight.src = Images.roundRight;
        roundRight.onload = () => {
          console.log("callledddddddd roundRight");

          let logo = new Image();
          logo.src = Images.logo;
          logo.onload = () => {
            console.log("callledddddddd logo");

            let greencycle = new Image();
            greencycle.src = Images.greencycle;
            greencycle.onload = () => {
              console.log("callledddddddd greencycle");
              setTimeout(() => {
                const element = document.getElementById("htmlElementToConvert");
                html2canvas(element, {
                  useCORS: true,
                  logging: true,
                  proxy: form?.photoUrl?.value,
                  letterRendering: 1,
                }).then(async (canvas) => {
                  setOpenCertificate(false);
                  const image = canvas.toDataURL("image/png");
                  console.log(
                    "callleddddddddcallledddddddd callledddddddd",
                    image
                  );

                  let bodyImage = {
                    base64Image: image.replace("data:image/png;base64,", ""),
                  };
                  imageURL = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}admin/user/uploadPass`,
                    bodyImage
                  );
                  let body = {
                    id: 0,
                    address: form.address.value,
                    age: form.age.value,
                    area: "",
                    bloodGroup: form.bloodGroup.value,
                    city: "",
                    email: form.email.value,
                    emergencyNumber: form.emergencyNumber.value,
                    gender: form.gender.value,
                    governmentId: form.governmentId.value,
                    mobileNo: form.mobileNo.value,
                    name: form.name.value,
                    paymentId: "",
                    photoUrl: form.photoUrl.value,
                    pinCode: form.pinCode.value,
                    startVenue: form.startingVenue.value,
                    state: "",
                    termsAndConditions: "True",
                    travellingDistance: 0,
                    tshirtSize: form.tshirtSize.value,
                    userType: type === "pay" ? "PARTICIPANT" : "SUPERHERO",
                    passUrl: imageURL.data.data.url,
                    student: isStudent,
                    promoCode: "",
                  };
                  if (activeTab === 2 && status) {
                    body.promoCode = form.promoCode.value;
                    callApi({ body, image: imageURL.data.data.url });
                    setIsLoading(false);
                    return;
                    // else {
                    //   if (status === false) {
                    // notification.open({
                    //   description: "Invalid promocode!",
                    //   placement: "bottomRight",
                    //   type: "error",
                    // });
                    // setIsLoading(false)
                    // return;
                    //   }
                    // }
                  }
                  if (type === "pay") {
                    handlePayment(async (response) => {
                      body.paymentId = response?.razorpay_payment_id;
                      callApi({ body, image: imageURL.data.data.url });
                    });
                  } else {
                    callApi({ body, image: imageURL.data.data.url });
                  }
                  return;
                  // const link = document.createElement("a");
                  // link.href = image;
                  // link.download = "downloaded_image.png";
                  // document.body.appendChild(link);
                  // link.click();
                  // document.body.removeChild(link);

                  // Do something with the image data, e.g., display it in an img tag or save it.
                });
              }, 1000);
            };
          };
        };
      };
    };
  };

  const handleSubmit = async ({ type }) => {
    //
    try {
      setIsLoading(true);
      let isValid = true;

      Object.keys(form).forEach((fieldName) => {
        const { value, regex } = form[fieldName];
        let isCheck = true;
        if (activeTab === 1) {
          if (
            fieldName === "startingVenue" ||
            fieldName === "tshirtSize" ||
            fieldName === "photoUrl" ||
            fieldName === "governmentId"
          ) {
            isCheck = false;
          }
        }
        if (regex && isCheck) {
          const pattern = new RegExp(
            fieldName === "age" && isStudent ? /^[0-9]{2}$/ : regex
          );

          if (!pattern.test(value)) {
            isValid = false;

            setForm((prevForm) => ({
              ...prevForm,
              [fieldName]: {
                ...prevForm[fieldName],
                isValid: false,
              },
            }));
          }
        }
      });

      if (isValid) {
        const isMobileExist = await isMobileNoExist(
          form.mobileNo.value,
          form.email.value
        );

        if (!isMobileExist) {
          if (form.promoCode.value) {
            promoCodeCheckFn(form.promoCode.value, (response, status) => {
              setPromoCodeDetail({
                promoCodeResponce: response ? response : {},
                isPromocodeValid: status,
              });
              if (status) {
                payForCauseHero(type, status);
              } else {
                notification.open({
                  description: "Invalid promocode!",
                  placement: "bottomRight",
                  type: "error",
                });
                setIsLoading(false);
              }
            });
          } else {
            payForCauseHero(type);
          }
          // if (type == "pay") {
          // } else {
          // payForSuperHero(type);
          // }
          // setOpenCertificate(true);
        } else {
          // notification.open({
          //   description: "Mobile number already exist!",
          //   placement: "bottomRight",
          //   type: "error",
          // });
          setIsLoading(false);
        }
      } else {
        notification.open({
          description: "Mandatory fields are missing.",
          placement: "bottomRight",
          type: "error",
        });
        setIsLoading(false);
      }
    } catch (err) {
      notification.open({
        description: "Something went wrong!",
        placement: "bottomRight",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (activeTab === 1) {
  //     setForm({
  //       ...form,
  //       startingVenue: {
  //         ...form.startingVenue,
  //         regex: "",
  //       },
  //       tshirtSize: {
  //         ...form.tshirtSize,
  //         regex: "",
  //       },
  //       photoUrl: {
  //         ...form.photoUrl,
  //         regex: "",
  //       },
  //       governmentId: {
  //         ...form.governmentId,
  //         regex: "",
  //       },
  //     });
  //   } else if (activeTab === 2) {
  //     setIsTermChecked(false);
  //     setForm(initFormObject);
  //   }
  // }, [activeTab]);

  console.log(form?.photoUrl, "Dfffffffffff");

  return (
    <div className=" overflow-hidden">
      <div className="bg-[#F1F6FF] ">
        <div className="pt-5">
          {/* <h6 className="py-[18px] px-8 text-[25px] text-left font-extrabold bg-[#E0EBFF]">
            {activeTab === 1
              ? "Become a SuperHero and Register for 333 km Cyclothon from Surat to Sarangpur"
              : "Become a CauseHero and Register for 10 km Cyclothon"}
          </h6> */}
          <h6 className="py-[18px] px-8 text-[30px] -tracking-tighter text-center font-extrabold bg-[#E0EBFF]">
            Registration has been closed
          </h6>
        </div>
        <div className="px-[8.333vw] py-8">
          <div className="md:mb-10">
            <div className="mb-7 flex flex-col sm:flex-row items-center">
              <InputTitle title="Name" />
              <Input
                type="name"
                placeholder="Enter Name"
                value={form.name?.value}
                formData={form.name}
                handleInputChange={handleInputChange}
              />
            </div>
            {activeTab === 2 && (
              <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-10 gap-y-6">
                <div className="flex flex-col sm:flex-row items-center">
                  <InputTitle title="Starting Location" />
                  <div className="flex flex-col w-full">
                    <Select
                      selected={{
                        name: form.startingVenue?.value,
                      }}
                      className="!w-full"
                      placeholder="Select Location"
                      options={startingLocation}
                      handleChange={(e) => {
                        setForm({
                          ...form,
                          startingVenue: {
                            ...form.startingVenue,
                            isValid: true,
                            value: e.name,
                          },
                        });
                      }}
                    />
                    {!form.startingVenue?.isValid && (
                      <span className="w-full text-left mt-2 text-[13px] text-red-500">
                        {form.startingVenue?.errorMessage}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-10 gap-y-6">
              <div className="mb-7 flex flex-col sm:flex-row items-center">
                <InputTitle title="Gender" />

                <div className="flex items-center gap-x-5 w-full justify-start">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      value="male"
                      checked={form.gender.value === "male"}
                      onChange={() =>
                        handleInputChange({ type: "gender", value: "male" })
                      }
                      className="form-radio h-5 w-5 text-[#007F3D] cursor-pointer"
                    />
                    <span className="ml-2 text-gray-700">Male</span>
                  </label>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      value="female"
                      checked={form.gender.value === "female"}
                      onChange={() =>
                        handleInputChange({ type: "gender", value: "female" })
                      }
                      className="form-radio h-5 w-5 text-[#007F3D] cursor-pointer"
                    />
                    <span className="ml-2 text-gray-700">Female</span>
                  </label>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="radio"
                      value="other"
                      checked={form.gender.value === "other"}
                      onChange={() =>
                        handleInputChange({ type: "gender", value: "other" })
                      }
                      className="form-radio h-5 w-5 text-[#007F3D] cursor-pointer"
                    />
                    <span className="ml-2 text-gray-700">Other</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-10 gap-y-6">
              <div className="mb-7 flex flex-col sm:flex-row items-center">
                <InputTitle title="Age" />
                <Input
                  placeholder="Age"
                  type="age"
                  value={form.age?.value}
                  formData={form.age}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="mb-7 flex flex-col sm:flex-row items-center">
                <InputTitle title="Blood Group" />
                <div className="w-full flex flex-col">
                  <Select
                    selected={{
                      name: form.bloodGroup?.value,
                    }}
                    placeholder="Select Blood Group"
                    options={bloodGroup}
                    className="!w-full !z-20"
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        bloodGroup: {
                          ...form.bloodGroup,
                          value: e.name,
                          isValid: true,
                        },
                      });
                    }}
                  />
                  {!form.bloodGroup?.isValid && (
                    <span className="w-full text-left mt-2 text-[13px] text-red-500">
                      {form.bloodGroup?.errorMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-10 gap-y-6">
              <div className="mb-7 flex flex-col sm:flex-row items-center">
                <InputTitle title="Mobile Number" />
                <Input
                  placeholder="Enter Number"
                  type="mobileNo"
                  value={form.mobileNo?.value}
                  formData={form.mobileNo}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="mb-7 flex flex-col sm:flex-row items-center">
                <InputTitle title="Emergency Mobile Number" />
                <Input
                  placeholder="Enter Number"
                  type="emergencyNumber"
                  value={form.emergencyNumber?.value}
                  formData={form.emergencyNumber}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-7 flex flex-col sm:flex-row items-center">
              <InputTitle title="Email" />
              <Input
                type="email"
                placeholder="Enter email"
                value={form.email?.value}
                formData={form.email}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="mb-7 flex flex-col sm:flex-row items-center">
              <InputTitle title="Address" />
              <div className="w-full flex flex-col">
                <textarea
                  className="w-full py-2.5 px-3 rounded-[8px] textarea"
                  placeholder="Address"
                  rows={3}
                  value={form.address?.value}
                  maxLength={form.address?.maxLength}
                  onChange={(e) =>
                    handleInputChange({
                      type: "address",
                      value: e.target?.value,
                    })
                  }
                />
                {!form.address?.isValid && (
                  <span className="w-full text-left mt-2 text-[13px] text-red-500">
                    {form.address?.errorMessage}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-6">
              <div className="flex flex-col sm:flex-row items-center">
                <InputTitle title="Pincode" />
                <Input
                  placeholder="Pincode"
                  type="pinCode"
                  value={form.pinCode?.value}
                  formData={form.pinCode}
                  handleInputChange={handleInputChange}
                />
              </div>
              {activeTab === 2 && (
                <div className="flex flex-col sm:flex-row items-center">
                  <InputTitle title="T-Shirt Size" />
                  <div className="flex flex-col w-full">
                    <Select
                      selected={{
                        name: form.tshirtSize?.value,
                      }}
                      placeholder="S"
                      options={sizes}
                      className=" !z-20"
                      handleChange={(e) => {
                        setForm({
                          ...form,
                          tshirtSize: {
                            ...form.tshirtSize,
                            value: e.name,
                            isValid: true,
                          },
                        });
                      }}
                    />
                    {!form.tshirtSize?.isValid && (
                      <span className="w-full text-left mt-2 text-[13px] text-red-500">
                        {form.tshirtSize?.errorMessage}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="mb-6 grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-6">
              <div className="flex flex-col sm:flex-row items-center">
                <InputTitle title="Upload Photo" />
                <ImageUploadInput
                  handleUploadImage={handleUploadImage}
                  type="photoUrl"
                  formData={form.photoUrl}
                  handleInputChange={handleInputChange}
                  setForm={setForm}
                />
              </div>
              {activeTab === 2 && (
                <div className="flex flex-col sm:flex-row items-center">
                  <InputTitle title="Upload Government ID" />
                  <ImageUploadInput
                    handleUploadImage={handleUploadImage}
                    type="governmentId"
                    placeholder="Aadhar Card"
                    formData={form.governmentId}
                    handleInputChange={handleInputChange}
                  />
                </div>
              )}
            </div>

            {activeTab === 2 && (
              <div>
                <Checkbox
                  onChange={(e) => setIsStudent(e.target.checked)}
                  checked={isStudent}
                >
                  <InputTitle title="Are you a student?" />
                </Checkbox>
              </div>
            )}
            {activeTab === 2 && (
              <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-10 gap-y-6 mt-[20px]">
                <div className="mb-7 flex flex-col sm:flex-row items-center">
                  <InputTitle title="Enter PromoCode" />
                  <Input
                    placeholder="Enter Promocode"
                    type="promoCode"
                    value={form.promoCode?.value}
                    formData={form.promoCode}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </div>
            )}
          </div>
          {activeTab === 2 && (
            <div className="max-w-fit mb-5 pb-2.5 font-bold border-b-[2px] border-black">
              Registration Fees <span className="ml-20">₹ 150/-</span>
            </div>
          )}
          <div className="flex flex-col mb-5">
            <Checkbox
              checked={isTermChecked}
              onChange={() => setIsTermChecked(!isTermChecked)}
              className="mb-3"
            >
              <div
                onClick={() => {
                  navigation("/termsandconditions");
                }}
                className=" cursor-pointer"
              >
                I accept and agree to{" "}
                <span className="underline gradient_txt border-b border-[#00A593]">
                  Terms & Conditions
                </span>
              </div>
            </Checkbox>
            {activeTab === 2 && (
              <>
                <div className="mt-[20px]">
                  <span className="font-semibold">Note:</span>
                  <ul className="list-disc ml-[20px]">
                    {/* <li>Students registering for this event who present their
                      valid ID at the ground will be eligible for a complete refund
                      after the event concludes.</li> */}
                    <li>
                      Causeheroes registering and participating in this event
                      will be eligible for a complete refund after the event
                      concludes.
                    </li>
                  </ul>
                </div>
                {/* <span className="font-semibold">
                  Note: Students registering for this event who present their
                  valid ID at the ground will be eligible for a complete refund
                  after the event concludes.
                </span>
                <span className="font-semibold">
                  Note: Causeheroes registering and participating in this event will be eligible for a complete refund after the event concludes.
                </span> */}
              </>
            )}
          </div>
          <div className="flex flex-col justify-center items-center">
            <p className="text-red-500 my-2 text-center text-[30px]">Registration has been closed.</p>
            <button
              // disabled={!isTermChecked}
              disabled={true}
              className="w-[600px] py-1.5 text-[22px] text-center text-white rounded-[10px] cursor-not-allowed"
              style={{
                background: "linear-gradient(180deg, #0079C0 0%, #007F3D 100%)",
                // opacity: !isTermChecked && 0.5,
                opacity: 0.5,
              }}
              onClick={() => {
                const type = activeTab === 2 ? "pay" : "";
                handleSubmit({ type });
              }}
            >
              {isLoading ? (
                <Spin />
              ) : activeTab === 2 && !form.promoCode?.value ? (
                "Proceed To Pay"
              ) : (
                "Submit"
              )}
            </button>
            {
              <div
                onClick={() => {
                  setIsOpenPassModal(true);
                }}
                className="self-center text-[#003B71] mt-1 cursor-pointer"
              >
                Already Registered? Download Certificate here.
              </div>
            }
          </div>
        </div>
      </div>
      <PassDownloadModal
        open={isOpenPassModal}
        onClose={() => {
          setIsOpenPassModal(false);
        }}
      />
      {openCertificate ? (
        <div className="h-[100vh] w-[100vw] absolute bg-white justify-center items-center flex top-0">
          <Spin size="large" />
        </div>
      ) : null}
      {/* <div className="flex w-full pt-96 flex-row ">
            <div className="uppercase text-[70px] w-[60%] font-bold  ml-10 text-white z-50">
              {" "}
              I am pedalling for a noble cause...
            </div>
            <div className="w-[50%] items-center justify-center flex flex-col">
              <div className="h-[400px] w-[400px] overflow-hidden justify-center flex  rounded-full border-[#A4E85C] border-[15px] bg-white">
                <img
                  crossorigin="*"
                  src={form?.photoUrl?.value}
                  className="h-[350px] w-[350px] object-contain self-center"
                />
              </div> */}

      {openCertificate ? (
        <div
          id="htmlElementToConvert"
          className={`relative  min-w-[1080px] max-w-[1080px] w-[1080px]  bg-[#003C99]`}
        >
          <img
            src={Images.leftTop}
            className="absolute top-0 left-0 h-[400px]"
            alt="test"
          />
          <img
            src={Images.roundRight}
            className="absolute right-0 top-10 h-[200px]"
            alt="test"
          />
          <div className="bg-white p-[5px] rounded-e-xl absolute top-28">
            <img src={Images.logo} className="h-[190px]  z-10" alt="test" />
          </div>
          <div className="flex w-full pt-96 flex-row ">
            <div className="uppercase text-[70px] w-[60%] font-bold  ml-10 text-white z-50">
              {" "}
              I am pedalling for a noble cause...
            </div>
            <div className="w-[50%] items-center justify-center flex flex-col">
              <div className="h-[400px] w-[400px] overflow-hidden flex justify-center  rounded-full border-[#A4E85C] border-[15px] bg-white">
                <img
                  crossorigin="*"
                  src={form?.photoUrl?.value}
                  className="h-[350px] object-contain self-center"
                />
              </div>

              <div className="w-[100%] mt-10 items-end flex justify-end">
                <div className="relative w-[100%] ">
                  <div className="relative  bg-[#A4E85C]  h-[60px] text-black w-[100%]  text-center py-2 mt-5">
                    <h1 className="absolute text-[30px] font-bold  -top-2 right-0 left-0">
                      {form?.name?.value}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uppercase text-[30px] w-[50%] ml-10 -mt-1 text-white font-bold">
            <h1>
              {activeTab == 1
                ? "join me for cycling from SMC Party Plot, Umra, Surat and become a superhero"
                : `join me for 10 km cycling from ${form?.startingVenue?.value} and become a causehero`}
            </h1>

            <div className="relative  bg-[#A4E85C]  h-[60px] text-black w-[80%] text-center py-2 mt-5">
              <h1 className="absolute -top-2 right-0 left-0">
                {activeTab == 1
                  ? "31st January 2024"
                  : form?.startingVenue?.value == startingLocation[0].name
                  ? "31st January 2024"
                  : "1st February 2024"}
              </h1>
            </div>
            {console.log(
              "activeTab == 2 && promoCodeDetail?.isPromocodeValid",
              activeTab == 2 && promoCodeDetail?.isPromocodeValid
            )}
            <h1>
              {activeTab == 2 && promoCodeDetail?.isPromocodeValid
                ? `${promoCodeDetail?.promoCodeResponce?.data}`
                : ""}
            </h1>
          </div>
          <div className="flex flex-row w-[100%]">
            <div className="w-[50%]">
              <img
                src={Images.roundRight}
                className="rotate-180 mt-10 absolute  bottom-32 h-[200px]"
                alt="test"
              />
            </div>
            <div className="w-[50%] relative -mt-20">
              <img src={Images.greencycle} className="" alt="test" />
              {/* greencycle */}
            </div>
          </div>
          <div className="flex flex-row pb-10 mt-2 text-white font-bold text-[25px] gap-3  justify-center">
            <h1>info@shishcyclothon.com</h1>
            <h1>www.shishcyclothon.com</h1>
            <h1>+91 7861880492</h1>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuperheroRegisteration;
