import React from "react";

const EventDetail = () => {
  return (
    <div className="py-[60px] bg-[#1A1917]">
      <h6 className="py-0 sm:py-[30px] md:pb-[60px] text-white  text-[30px] sm:text-[40px] text-center font-bold">
        EVENT DETAILS
      </h6>
      <div className="mx-[16px] md:mx-[13.021vw] pt-6 pb-4 text-[14px] md:text-[24px] lg:text-[34px] text-white font-semibold flex items-center justify-between border-b-[3px] border-[#00A593]">
        <p>Starting Location</p>
        <div className="min-w-[50%] flex items-center justify-between">
          <p>Date</p>
          <p>Start Time</p>
        </div>
      </div>
      <div className="mx-[16px] md:mx-[13.021vw] pt-6 pb-4 text-[14px] md:text-[24px] lg:text-[34px] text-white flex items-center justify-between border-b-[3px] border-[#00A593]">
        <p>SMC Party Plot, Umra, Surat</p>
        <div className="min-w-[50%] flex items-center justify-between">
          <p>31st Jan, 2024</p>
          <p>6:00 am</p>
        </div>
      </div>
      <div className="mx-[16px] md:mx-[13.021vw] pt-6 pb-4 text-[14px] md:text-[24px] lg:text-[34px] text-white flex items-center justify-between border-b-[3px] border-[#00A593]">
        <p>SRP Ground, Sussen Circle, Vadodara</p>
        <div className="min-w-[50%] flex items-center justify-between">
          <p>1st Feb, 2024</p>
          <p>6:30 am</p>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
