import React from "react";

const InputTitle = ({ title, className }) => {
  return (
    <p
      className={`w-full lg:w-[254px] lg:min-w-[254px] text-[17px] text-[#161D21] ${className}`}
    >
      {title}
    </p>
  );
};

export default InputTitle;
