import React from "react";
import { Images } from "../../../constants/images";

function SupportAndEvent() {
  return (
    <div className="bg-[#1A1917] flex flex-col px-[10px] xl:px-[80px] py-[50px] mb-[30px]">
      <div className="w-full flex flex-col md:flex-row items-center">
        <div className="h-auto w-full md:w-[50%]">
          <div className="flex items-center mb-[10px] justify-start ml-[20px] md:ml-[40px] xl:ml-[140px]">
            <img
              src={Images.line}
              alt=""
              className="w-[60px] sm:w-[110px] mr-[20px]"
            />
            <span className="text-white text-xl md:text-2xl lg:text-4xl font-normal uppercase">
              SHISH CYCLOTHON
            </span>
          </div>
          <div className="flex flex-col">
            <span className="ml-[40px] md:ml-[60px] xl:ml-[200px] font-extrabold text-2xl lg:text-[40px] gradient_txt">
              SUPPORT & EVENT
            </span>
            <span className="ml-[130px] md:ml-[150px] xl:ml-[350px] font-extrabold text-2xl lg:text-[40px] gradient_txt sm:mt-[20px]">
              AMENITIES
            </span>
          </div>
        </div>

        <div className="w-full md:w-[50%] flex justify-between">
          <div className="relative mr-[10px] sm:mr-0">
            <img
              src={Images.cyclingFitness}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              Refreshment Station
            </span>
          </div>
          <div className="relative">
            <img
              src={Images.firstAidKit}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              MEDICAL SUPPORT
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center mt-[40px] justify-between h-[340px] sm:h-[450px]">
        <div className="w-full md:w-[49.4%] flex justify-between">
          <div className="relative mr-[10px] sm:mr-0">
            <img
              src={Images.supportCrew}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              SUPPORT CREW
            </span>
          </div>
          <div className="relative">
            <img
              src={Images.accommodation}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 sm:right-[60px] text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              ACCOMMODATION
            </span>
          </div>
        </div>
        <div className="w-full md:w-[49.4%] flex justify-between">
          <div className="relative mr-[10px] sm:mr-0">
            <img
              src={Images.meal}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              HEALTHY MEALS
            </span>
          </div>
          <div className="relative">
            <img
              src={Images.winnerMedal}
              className="h-[150px] sm:h-[200px] md:h-[300px] xl:h-[400px] w-[200px] md:w-[300px] xl:w-[400px]"
              alt="refreshment"
            />
            <span className="absolute bottom-0 left-0 right-0 text-center top-[18.281vw] sm:top-[9.281vw] text-[14px] md:text-[20px] xl:text-[30px] text-white sm:w-[50%] m-auto uppercase">
              FINISHER’S MEDAL & CERTIFICATE
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportAndEvent;
