import React from "react";
import { Images } from "../../constants/images";

function AboutShishCyclothon() {
  return (
    <div className="pl-[20px] lg:pl-[80px] py-[20px] lg:py-[96px] flex flex-col lg:flex-row items-center relative">
      <div className="w-full lg:w-[35%] lg:mr-[180px]">
        <div className="h-auto">
          <div className="flex items-center mb-[10px]">
            <img src={Images?.line} alt="" className="w-[110px] mr-[20px]" />
            <span className="text-[#1A1917] text-3xl font-normal">ABOUT</span>
          </div>
        </div>
        <img
          src={Images.aboutUsBanner}
          className="h-auto block sm:hidden lg:h-[410px]"
          alt="about cyclothon"
        />

        <div className="mx-[15px]">
          <span className="font-extrabold text-3xl lg:text-[40px] gradient_txt">
            SHISH CYCLOTHON
          </span>
          <div className="text-[#3F3F3F] text-[22px] font-normal mt-[20px]">
            <span>
              <b>Shish Cyclothon</b> is committed to promoting sustainability
              and health where wheels turn not only for competition but also for
              a noble cause!
            </span>
          </div>
          <div className="text-[#3F3F3F] text-[22px] font-normal mt-[20px]">
            <span>
              An initiative by <b>Shish Foundation</b>, it champions positive
              environmental and social impact through sustainable practices,
              fitness, and healthy lifestyle.
            </span>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-[65%] mt-[20px] lg:mt-0">
        <img
          src={Images.aboutUsBanner}
          className="hidden sm:block object-contain"
          alt="3 cyclists on a road at sunset"
        />
      </div>
    </div>
  );
}

export default AboutShishCyclothon;
