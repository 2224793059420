import { Images } from "../../constants/images";

const OurEventSupporter = ({ isSponsers }) => {
  return (
    <div className="px-4 py-[30px] bg-white">
      <div className="h-auto mb-[60px]">
        <div className="w-full flex justify-center items-center mb-[10px]">
          <img
            src={Images.line}
            alt="Surat Police Logo"
            className="w-[80px] sm:w-[110px] mr-[20px]"
          />
          <span className="text-[#1A1917] text-[22px] sm:text-[40px] font-normal">
            OUR{" "}
            <span className="text-[22px] sm:text-[48px] font-extrabold gradient_txt">
              {isSponsers ? "EVENT SPONSORS" : "EVENT SUPPORTERS"}
            </span>
          </span>
        </div>
      </div>
      {isSponsers ? (
        <>
          <div className="w-full mb-10 flex flex-col sm:flex-row items-center justify-center gap-10">
            <img
              alt="Growder Logo"
              src={Images.growderLogo}
              className="w-[250px] object-contain"
            />
            <img
              alt="growder_logo"
              src={Images.packagingDepotLogo}
              className="w-[250px] object-contain"
            />
          </div>
          <div className="w-full mb-16 grid sm:grid-cols-3 grid-cols-2 gap-5 items-center  sm:flex-wrap justify-items-center lg:flex lg:justify-around">
            <img
              alt="carbon Cube jewels"
              src={Images.supporter1}
              className="object-contain"
            />
            <img
              alt="Truth Jewels Logo"
              src={Images.supporter2}
              className="w-[95px] object-contain"
            />
            <img
              alt="Green Energy Logo"
              src={Images.supporter3}
              className="w-[157px] object-contain"
            />
            <img
              alt="Vrsist Logo"
              src={Images.supporter4}
              className="object-contain"
            />
            <img
              alt="SM Gold Logo"
              src={Images.supporter5}
              className="w-[146px] object-contain"
            />
            <img
              alt="Mahima Jewellers Logo"
              src={Images.supporter6}
              className="w-[226px] object-contain"
            />
            <img
              alt="Lotusstar Trading LLC Logo"
              src={Images.supporter7}
              className="w-[212px] object-contain"
            />
            <img
              alt="Demira Jewels Pvt. Ltd. Logo"
              src={Images.supporter8}
              className="object-contain"
            />
          </div>
          <h6 className=" sm:block hidden text-[30px] text-center font-medium">
            And many more Cause Ambassadors...
          </h6>
        </>
      ) : (
        <div className="w-full mb-5 flex flex-row justify-around sm:justify-center gap-0 sm:gap-10">
          <img
            alt="Surat Police Logo"
            src={Images.suratCityPolice}
            className="w-[100px] sm:w-[250px] h-[100px] sm:h-[250px] object-contain"
          />
          <img
            alt="SMC Logo"
            src={Images.SMC_Logo}
            className="w-[100px] sm:w-[250px] h-[100px] sm:h-[250px] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default OurEventSupporter;
