import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import Banner from "../../components/banner";
import Adventure from "./adventure";
import RegisterModal from "../../components/modal/registerModal";
import OurEventSupporter from "../../components/ourEventSupporter";
import { Images } from "../../constants/images";
import AboutShishCyclothon from "../../components/aboutShishCyclothon";
import VisionSection from "../../components/visionSection";
import FitIndia from "../../components/fitIndiaSection";
import AboutShish from "../../components/aboutShish";
import RegisterWithUs from "../../components/registerSection";
import InspirationSection from "../../components/inspirationSection";
import QuoteSection from "../../components/quoteSection";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div>
      <Helmet>
        <title>
          Shish Cyclothon - Surat Cyclothon Event by Shish Foundation
        </title>
        <meta
          name="description"
          content="Shish Cyclothon is committed to promoting sustainability and health where wheels turn not only for competition but also for a noble cause!"
        />
      </Helmet>
      <Banner openModal={openModal} />
      <Adventure openModal={openModal} />
      <AboutShishCyclothon />
      <InspirationSection />
      <QuoteSection screenSize={screenSize} />
      {screenSize < 450 ? (
        <img alt="living_bg" src={Images.livingBgMobile} />
      ) : (
        <img alt="living_bg" src={Images.livingBg} />
      )}
      <VisionSection />
      <FitIndia />
      <AboutShish />
      <OurEventSupporter />
      <RegisterWithUs openModal={openModal} />
      {/* <MapSeaction />
      <SuperHero />
      <SupportSection />
      <EventSection />
      <AboutUs />
      <AboutUs /> */}
      <RegisterModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={closeModal}
      />
    </div>
  );
}

export default Home;
