import { Images } from "../constants/images";

const Banner = ({ openModal, img }) => {
  return (
    <div className="relative">
      <img
        alt="Shish cyclothon - 2024"
        src={img ? img : Images.landingPageHeroSectionBGImage}
        className="w-full object-fill"
      />
      <div className="absolute top-[13.281vw] left-0 right-0 mx-auto">
        <h1 className="text-[20px] sm:text-[30px] md:text-[40px] lg:text-[90px] text-white text-center font-extrabold">
          SHISH Cyclothon 2024
        </h1>
        <h2 className="mb-2 lg:mb-5 text-[12px] sm:text-[22px] lg:text-[30px] 2xl:text-[50px] text-white text-center leading-[1.1] lg:leading-[1.1] font-light">
          <span className="text-[#00AC93] font-bold">S</span>ustainability &{" "}
          <span className="text-[#00AC93] font-bold">H</span>ealth by{" "}
          <span className="text-[#00AC93] font-bold">I</span>ndian{" "}
          <span className="text-[#00AC93] font-bold">S</span>uper
          <span className="text-[#00AC93] font-bold">H</span>eroes
        </h2>
        {/* <div className="flex items-center justify-center">
          <button
            className="py-2 lg:py-3 px-4 lg:px-6 text-[12px] md:text-[14px] lg:text-[16px] rounded-[5px] bg-white"
            onClick={() => openModal()}
          >
            REGISTER NOW
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
