import React from "react";
import { Images } from "../../constants/images";

function FitIndia() {
  return (
    <div className="bg-[#1A1917] py-[30px] lg:py-[80px] px-[30px] lg:px-[75px] flex flex-col md:flex-row justify-between">
      <div className="w-full md:w-[35%] lg:w-[50%]">
        <div className="w-full lg:w-[40%]">
          <div className="h-auto">
            <div className="flex items-center mb-[10px]">
              <img src={Images.line} alt="" className="w-[110px] mr-[20px]" />
              <span className="text-white text-3xl font-normal">FIT INDIA</span>
            </div>
          </div>

          <div className="ml-0 md:ml-[60px] mb-[20px] sm:mb-0">
            <span className="font-extrabold text-3xl lg:text-[40px] gradient_txt">
              SUPPORT
            </span>
            <div className="text-white text-[22px] font-normal mt-[20px]">
              <span>
                Fueled by the Fit India spirit, we embark on a journey of
                health, wellness, and collective fitness excellence.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-[65%] lg:w-[50%] flex flex-row justify-center sm:justify-start">
        <div className="mr-[10px] sm:mr-[16px] mb-[16px] sm:mb-0 flex justify-center">
          <img
            src={Images.fitIndiaImage}
            alt="Fit India Logo"
            className="w-auto sm:w-[392px] h-[161px] sm:h-[336px]"
          />
        </div>
        <div className="flex justify-center">
          <img
            src={Images.coupleCyclingImage}
            alt="cyclist couple riding together for exercise around the lake "
            className="w-auto sm:w-[392px] h-[161px] sm:h-[336px]"
          />
        </div>
      </div>
    </div>
  );
}

export default FitIndia;
